<template>
  <div id="sign_name">
    <van-nav-bar
      title="签名"
      left-arrow
      left-text="返回"
      @click-left="onClickLeft"
    />
    <VueSignaturePad
      id="signature"
      width="98%"
      height="500px"
      ref="signaturePad"
    />
    <div style="margin:15px;">
      <van-row>
        <van-col span="12">
          <van-button type="primary" @click="save"
            >保存签名</van-button
          ></van-col
        >
        <van-col span="12">
          <van-button type="default" @click="undo"
            >重新签名</van-button
          ></van-col
        >
      </van-row>
    </div>
  </div>
</template>

<script>
export default {
  name: 'Sign',
  methods: {
    undo() {
      this.$refs.signaturePad.undoSignature()
    },
    save() {
      const { isEmpty, data } = this.$refs.signaturePad.saveSignature()
      console.log(isEmpty)
      console.log(data)
    },
    onClickLeft() {
      history.go(-1)
    }
  }
}
</script>

<style scoped>
#sign_name {
  padding: 1%;
}
#signature {
  border: double 3px transparent;
  border-radius: 5px;
  background-image: linear-gradient(white, white),
    radial-gradient(circle at top left, #4bc5e8, #9f6274);
  background-origin: border-box;
  background-clip: content-box, border-box;
}
</style>
