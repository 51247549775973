<template>
  <div id="skeleton">
    <content-loader
      :width="414"
      :height="836"
      :speed="1"
      primaryColor="#f3f3f3"
      secondaryColor="#ecebeb"
    >
      <rect x="6" y="18" rx="5" ry="5" width="100" height="20" />
      <rect x="11" y="54" rx="0" ry="0" width="384" height="110" />
    </content-loader>
  </div>
</template>

<script type="text/javascript">
import { ContentLoader } from 'vue-content-loader'
export default {
  mounted() {},
  data() {
    return {
      width: 414,
    }
  },
  components: {
    ContentLoader,
  },
  methods: {},
}
</script>

<style lang="less" scoped></style>
