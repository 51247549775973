<template>
  <div id="pre-form">
    <van-nav-bar :title="pageTitle" left-arrow left-text="返回" @click-left="onClickLeft" />

    <van-form validate-first @failed="onFailed">
      <van-cell-group>
        <van-field
          readonly
          clickable
          label="预约时间"
          name="begin_time"
          :value="timeValue"
          placeholder="点击选择时间"
          @click="showPopup"
        />

        <van-popup v-model="show" position="bottom">
          <van-datetime-picker
            v-model="form.time"
            title="选择时间"
            :loading="isLoadingShow"
            :min-date="minDate"
            :filter="filter"
            :type="time_type"
            :formatter="formatter"
            @cancel="show = false"
            @confirm="confirmPicker"
          />
        </van-popup>

        <van-field
          v-if="isEnd"
          readonly
          clickable
          label="结束时间"
          name="end_time"
          :value="timeValueEnd"
          placeholder="点击选择时间"
          @click="showPopupEnd"
        />

        <van-popup v-model="showEnd" position="bottom">
          <van-datetime-picker
            v-model="form.end_time"
            title="选择时间"
            :loading="isLoadingShow"
            :min-date="minDate"
            :filter="filter"
            :type="time_type"
            :formatter="formatter"
            @cancel="show = false"
            @confirm="confirmPickerEnd"
          />
        </van-popup>

        <van-cell center title="是否需要客户确认">
          <template #right-icon>
            <van-switch v-model="form.is_message" size="24" />
          </template>
        </van-cell>

        <van-field
          v-show="form.is_message"
          v-model="form.content"
          rows="2"
          autosize
          label="消息内容"
          type="textarea"
          maxlength="500"
          placeholder="请输入消息内容"
          show-word-limit
        />
        <van-field
          v-model="form.remarks"
          rows="2"
          autosize
          label="其他备注"
          type="textarea"
          maxlength="500"
          placeholder="请输入备注内容"
          show-word-limit
        />
      </van-cell-group>

      <div style="margin: 16px;margin-top:30px;">
        <van-button round block type="info" @click="submit(0)" native-type="submit">开始预约</van-button>
      </div>
    </van-form>
  </div>
</template>

<script>
import storage from 'store'
import moment from 'moment'
import wxJs from "@/utils/wx-sdk";
export default {
  name: 'PreCreate',
  data() {
    return {
      cateInfo: {},
      time_type: 'datetime',
      form: {
        time: '',
        end_time: '',
        is_message: true,
        content: '',
        pre_type: 0,
        remarks:'',
      },
      chatInfo: {},
      isChat: false,
      pageTitle: '预约',
      cid: '',
      showPicker: false,
      yuyuetime1:"",
      yuyuetime2:"",
      timeValue: '',
      timeValueEnd: '',
      isValue: false,
      isEnd: false,
      show: false,
      showEnd: false,
      isLoadingShow: true,
      currentDate: moment(new Date()).format('YYYY-MM-DD HH:mm:SS'),
      minDate: new Date()
    }
  },
  mounted: function() {
    this.cid = this.$route.query.cid
    console.log('this.cid', this.cid)
    if (this.cid) {
      this.$store.dispatch('getPreCateId', this.cid)
      this.getCate()
    } else {
      return this.$router.push({ path: '/' })
    }
  },
  methods: {
    filter(type, options) {
      if (type === 'minute') {
        return options.filter(option => option % 10 === 0)
      }
      return options
    },
    // 提交内容
    submit(type) {
      if (!this.isValue && type == 0) {
        return this.$toast.fail('请选择时间')
      }
      if(this.isEnd && this.yuyuetime1>=this.yuyuetime2){
          return this.$toast.fail('结束时间需大于开始时间');
      }
      var chat = storage.get('GET_CHAT')
      console.log('chat', chat)

      this.form.begin_time = this.timeValue
      this.form.time = this.timeValue
      this.form.pre_type = type
      this.form.cate_id = this.$store.state.app.pre_cate_id
      this.form.guest_id = chat.id
      this.form.is_message = this.form.is_message ? 1 : 0

      console.log('postdata', this.form)
      var that = this;
      this.$store.dispatch('createPre', this.form).then(res => {
          if(res.data.savetag=="0"){
            this.$dialog
              .alert({
                title: '提示',
                message: res.message
              })
              .then(() => {
                // this.pushMessage(res)
                return this.$router.push({ path: '/' })
              })
              
          }else{
              wxJs
                .agentInit(["openExistedChatWithMsg"])
                .then(() => {
                    // 返回当前客户群的群聊ID
                    window.wx.invoke("openExistedChatWithMsg", {
                        chatId: res.data.chat_id,
                        msg: {
                            msgtype: "link",
                            link: {
                                title: res.data.message.title,
                                desc: res.data.message.desc,
                                url: res.data.message.link,
                                imgUrl: res.data.message.imgUrl
                            }
                        }
                    },function(res){
                        if (res.err_msg == "openExistedChatWithMsg:ok"){
                            return that.$router.push({ path: '/' })
                        }else{
                            return that.$router.push({ path: '/' })
                        }
                    });
                })
          }
          
      })
    },

    // 返回上页
    onClickLeft() {
      history.go(-1)
    },

    // 获取商户详情
    getCate() {
      this.$store.dispatch('getPreCateInfo').then(res => {
        this.cateInfo = this.$store.state.app.pre_cate_info
        this.title = this.cateInfo.title
        this.pageTitle = '预约' + this.cateInfo.title
        console.log('time_type', res)
        console.log('time_type', this.cateInfo)
        this.time_type = this.cateInfo.time_type
        if (this.cateInfo.is_end == '1') {
          this.isEnd = true
        }
        if (this.cateInfo.template != null) {
          this.form.content = this.cateInfo.template
        }
      })
    },
  // 预约完成后，向群内发消息；
  pushMessage(parms){
    let that = this;
    return new Promise( resolve => {
      parms;
      let chatID =  that.$store.dispatch('getChat');
			wxJs.agentInit(["openExistedChatWithMsg"])
				.then(() => {
					// 返回当前客户群的群聊ID
					window.wx.invoke("openExistedChatWithMsg", {
						chatId: chatID,
						msg: {
							msgtype: "link",
							link: {
								title: "干点活专属消息",
								desc: "身边的技术合伙人",
								url: "http://www.gandianhuo.com/",
								imgUrl: "http://www.gandianhuo.com/tpl/default/asset/img/gdhlogo.png"
							}
						}
					},function(res){
						if (res.err_msg == "openExistedChatWithMsg:ok")
						{
						alert("发送成功");
						}else {
						alert(res.err_msg);
						}
					}
							
					);
				})
				.catch(() => {
					that.$notify({
						type: "primary",
						message: "请重新进入",
						onClose: () => {
							window.wx.closeWindow();
						},
					});
			});
      resolve();
    })
  },
    
    // 异步校验函数返回 Promise
    asyncValidator(val) {
      return new Promise(resolve => {
        this.$toast.loading('验证中...')

        setTimeout(() => {
          this.$toast.clear()
          resolve(/\d{6}/.test(val))
        }, 1000)
      })
    },

    // 出错提示
    onFailed(errorInfo) {
      console.log('failed', errorInfo)
    },

    // 显示弹窗
    showPopup() {
      this.show = true
      this.isLoadingShow = true
      setTimeout(() => {
        this.isLoadingShow = false
      }, 500)
    },
    // 显示弹窗
    showPopupEnd() {
      this.showEnd = true
      this.isLoadingShow = true
      setTimeout(() => {
        this.isLoadingShow = false
      }, 500)
    },

    // 确认选择的时间
    confirmPicker(val) {
      if (this.time_type == 'date') {
        this.timeValue = moment(val).format('YYYY-MM-DD')
      } else {
        this.timeValue = moment(val).format('YYYY-MM-DD HH:mm:SS')
      }
      this.yuyuetime1 = moment(val);
      this.week = moment(val).format("dddd")
      this.show = false
      this.isValue = true
    },
    // 确认选择的时间
    confirmPickerEnd(val) {
      if (this.time_type == 'date') {
        this.timeValueEnd = moment(val).format('YYYY-MM-DD')
      } else {
        this.timeValueEnd = moment(val).format('YYYY-MM-DD HH:mm:SS')
      }
      this.yuyuetime2 = moment(val);
      this.week = moment(val).format("dddd")
      this.showEnd = false
      this.isValue = true
    },
    // 选项格式化函数
    formatter(type, value) {
      if (type === 'year') {
        return `${value}年`
      } else if (type === 'month') {
        return `${value}月`
      } else if (type === 'day') {
        return `${value}日`
      } else if (type === 'hour') {
        return `${value}时`
      } else if (type === 'minute') {
        return `${value}分`
      } else if (type === 'second') {
        return `${value}秒`
      }
      return value
    }
  }
}
</script>

<style scoped></style>
