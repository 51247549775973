<template>
  <div id="guest">
    <van-nav-bar :title="pageTitle" left-arrow left-text="返回" @click-left="onClickLeft" />
    <van-tabs animated sticky swipeable v-model="active">
      <van-tab title="基本" name="base">
        <div>
          <van-cell title="地图定位" value="客户地址定位" to="/map" />

          <van-collapse v-model="baseCollapse" accordion border>
            <van-collapse-item v-for="(group,index) in base.items" :key="index" :title="group.cate" :name="'A'+index">
              <van-col span="24" v-for="(item,index2) in group.config" :key="index2">
                <!-- 文本输入框 -->
                <van-field v-if="
                    item.type == 'text' ||
                    item.type == 'tel'
                  " :type="item.type" v-model="config[item.id]" :name="item.name" :label="item.title"
                  :placeholder="item.value=='null'?'':item.value" @input="saveInput(item)" />

                <van-field v-if="item.type == 'textarea'" :type="item.type" autosize show-word-limit
                  v-model="config[item.id]" :name="item.name" :label="item.title"
                  :placeholder="item.value=='null'?'':item.value" @input="saveInput(item)" />

                <!-- 下拉选择 -->
                <one-dropdownlist v-if="item.type == 'dropDownList'" :item="item" :guest_id="chat.id" />

                <!-- 日期 -->
                <one-datetime v-if="
                item.type == 'datetime' ||
                  item.type == 'date' ||
                  item.type == 'time'
              " :item="item" :guest_id="chat.id" />
              </van-col>
            </van-collapse-item>
          </van-collapse>
        </div>
      </van-tab>

      <van-tab title="动态">
        <van-list v-model="timeline.loading" :finished="timeline.finished" finished-text="更新完成" @load="onLoadTimeline">
          <van-cell v-for="item in timeline.items" :key="item.id">
            <template #title>
              <div>
                <van-tag type="success">{{item.action}}</van-tag>
              </div>
            </template>
            <template #label>
              <div>
                <div>{{ item.remark }}</div>
                <div>发生时间：{{ item.created_at | dateformat }}</div>
              </div>
            </template>
          </van-cell>
        </van-list>
      </van-tab>

      <van-tab title="合同">
        <van-list v-model="contract.loading" :finished="contract.finished" finished-text="更新完成" @load="onLoadContract">
          <van-cell v-for="item in contract.items" :key="item.id">
            <template #title>{{item.cate_name}} | {{item.created_at}}</template>
            <template #label>
              <van-button v-if="item.sign_status =='0'" @click="oneContract(item)" type="primary" size="mini">继续编辑合同
              </van-button>&nbsp;
              <van-tag :type="item.sign_status =='1' ? 'success':'warning'">{{ item.sign_status_name }}</van-tag>&nbsp;
              <van-tag :type="item.is_owner=='1'?'success':'warning'">{{ item.is_owner_name }}</van-tag>&nbsp;
              <van-tag :type="item.is_merge=='1'?'success':'warning'">{{ item.is_merge_name }}</van-tag>&nbsp;
              <van-tag v-show="item.is_merge == '1'" type="success" @click="shareMessageContract(item)">分享会话</van-tag>
            </template>
          </van-cell>
        </van-list>
      </van-tab>

      <van-tab title="预约">
        <van-list v-model="pre.loading" :finished="pre.finished" finished-text="更新完成" @load="onLoadPre">
          <van-cell v-for="item in pre.items" :key="item.id">
            <template #title>
              <div>
                <van-tag :type="item.pre_status != '3'?'success':'warning'">{{item.pre_status_name}}</van-tag>
                {{ item.cate_name }}
              </div>
            </template>
            <template #label>
              <van-row>
                <van-col span="12">
                  发起人：
                  <b>{{ item.member_name }}</b>
                </van-col>
                <van-col span="12">预约时间：{{ item.begin_time }}</van-col>
              </van-row>
              <van-row>
                <van-col>
                  发起时间：
                  <b>{{ item.created_at | datetime }}</b>
                </van-col>
              </van-row>
              <van-row>
                <van-col span="12">
                  通知客户：
                  <van-tag :type="item.is_message != '0'?'success':'warning'">{{item.is_message != '0'?'是':'否'}}
                  </van-tag>
                </van-col>
                <van-col span="12">
                  处理状态：
                  <van-tag :type="item.check_status != '0'?'success':'warning'">{{ item.check_status_name }}</van-tag>
                </van-col>
              </van-row>
              <div>内容：{{ item.content }}</div>
              <div>
                <van-button color="#7232dd" size="mini" plain @click="reMessage(item)">重新发送消息</van-button>
              </div>
            </template>
          </van-cell>
        </van-list>
      </van-tab>

      <van-tab title="相册">
        <van-list v-model="gallery.loading" :finished="gallery.finished" finished-text="更新完成" @load="onLoadGallery">
          <van-cell v-for="item in gallery.contracts" :key="item.id">
            <template #title>
              <div>
                <van-tag type="success">{{item.cate_name}}</van-tag>
                {{ item.created_at | dateformat }}
              </div>
            </template>
            <template #label>
              <div class="van-uploader">
                <div class="van-uploader__wrapper">
                  <div class="van-uploader__preview" v-for="(image, index) in item.maps" :key="index">
                    <div class="van-image van-uploader__preview-image" @click="showGallery(item.maps)">
                      <van-image :src="image" class="van-image__img" style="object-fit: cover;">
                        <template v-slot:error>加载失败</template>
                      </van-image>
                    </div>
                  </div>
                </div>
              </div>
            </template>
          </van-cell>
          <van-cell v-for="item in gallery.take" :key="item.id">
            <template #title>
              <div>
                <van-tag type="success">{{item.cate_name}}拍照</van-tag>
                {{ item.created_at | dateformat }}
                <van-button v-if="item.is_sign == '0'" type="primary" plain size="mini" @click="shareSign(item)">发送签名
                </van-button>
                <van-tag v-else type="success" @click="showGallery([item.sign])">已签名</van-tag>
              </div>
            </template>
            <template #label>
              <div class="van-uploader">
                <div class="van-uploader__wrapper">
                  <div class="van-uploader__preview" v-for="(image, index) in item.covers" :key="index">
                    <div class="van-image van-uploader__preview-image" @click="showGallery(item.covers)">
                      <van-image :src="image" class="van-image__img" style="object-fit: cover;">
                        <template v-slot:error>加载失败</template>
                      </van-image>
                    </div>
                  </div>
                  <div v-show="item.video" class="van-uploader__preview">
                    <div class="van-image van-uploader__preview-image" @click="onVideo(item)">
                      <van-image :src="image" class="van-image__img" style="object-fit: cover;">
                        <template v-slot:error>
                          <van-icon name="video" size="30" />
                        </template>
                      </van-image>
                    </div>
                  </div>
                </div>
              </div>
            </template>
          </van-cell>
        </van-list>
        <van-popup v-model="showVideo">
          <vueMiniPlayer :video="video" />
        </van-popup>
      </van-tab>

      <van-tab title="单据上传">
        <van-list v-model="form.loading" :finished="form.finished" finished-text="更新完成" @load="onLoadForm">
          <van-cell v-for="item in form.items" :key="item.id">
            <template #title>{{item.title}} => {{item.id}}</template>
            <template #label>
              <van-button v-if="item.status =='0'" @click="oneForm(item)" type="primary" size="mini">继续编辑单据</van-button>
              &nbsp;
              <van-tag :type="item.is_read =='1' ? 'success':'warning'">{{ item.is_read == '1' ? '已读' : '未读' }}
              </van-tag>&nbsp;
              <van-tag :type="item.status=='1'?'success':'warning'">{{ item.status == '1' ? '审核通过' : '审核中' }}</van-tag>
            </template>
          </van-cell>
        </van-list>
      </van-tab>
    </van-tabs>
  </div>
</template>

<script>
  import {
    ImagePreview
  } from 'vant'
  import {
    fetchGet,
    fetchPost
  } from '@/utils/http.js'
  import OneDropdownlist from '@/components/one-dropdownlist/index.vue'
  import OneDatetime from '@/components/one-datetime/index.vue'
  import wxJs from '@/utils/wx-sdk'
  import {
    showUrl
  } from '@/utils/params'
  import storage from 'store'

  export default {
    name: 'Guest',
    components: {
      OneDropdownlist,
      OneDatetime,
      [ImagePreview.Component.name]: ImagePreview.Component,
    },
    data() {
      return {
        baseCollapse: 'A0',
        count: 0,
        chat: {},
        video: {},
        isLoading: false,
        active: 0,
        showVideo: false,
        it: {},
        pageTitle: '群档案',
        config: [],
        base: {
          items: [],
          config: [],
          refreshing: false,
          loading: false,
          error: false,
          finished: false,
        },
        timeline: {
          items: [],
          refreshing: false,
          loading: false,
          error: false,
          finished: false,
        },
        contract: {
          items: [],
          refreshing: false,
          loading: false,
          error: false,
          finished: false,
        },
        form: {
          items: [],
          refreshing: false,
          loading: false,
          error: false,
          finished: false,
        },
        gallery: {
          items: [],
          contracts: [],
          take: [],
          refreshing: false,
          loading: false,
          error: false,
          finished: false,
        },
        pre: {
          items: [],
          refreshing: false,
          loading: false,
          error: false,
          finished: false,
        },
      }
    },

    mounted: function () {
      var guestactive = sessionStorage.getItem("guestactive");
      if (guestactive) {
        this.active = Number(guestactive);
      }
      this.chat = storage.get('GET_CHAT')
      this.getChatId(this.chat.id)
      // todo
      this.getWorkCheck()


    },
    methods: {
      groupBy(array, f) {
        const groups = {}
        array.forEach(function (o) {
          //注意这里必须是forEach 大写
          const group = JSON.stringify(f(o))
          groups[group] = groups[group] || []
          groups[group].push(o)
        })
        return Object.keys(groups).map(function (group) {
          return groups[group]
        })
      },
      reMessage(item) {
        console.log('item', item)
        fetchGet(`pre/re-message`, {
          id: item.id
        }).then((res) => {
          wxJs
            .agentInit(["openExistedChatWithMsg"])
            .then(() => {
                // 返回当前客户群的群聊ID
                window.wx.invoke("openExistedChatWithMsg", {
                    chatId: res.data.chat_id,
                    msg: {
                        msgtype: "link",
                        link: {
                            title: res.data.message.title,
                            desc: res.data.message.desc,
                            url: res.data.message.link,
                            imgUrl: res.data.message.imgUrl
                        }
                    }
                },function(res){
                    if (res.err_msg == "openExistedChatWithMsg:ok"){
                        this.$toast({
                          message: '发送成功',
                          type: 'success'
                        })
                    }else{
                        this.$toast('发送失败')
                    }
                });
            })
        })
      },
      oneContract(item) {
        window.location.href = item.url
      },
      oneForm(item) {
        sessionStorage.setItem("guestactive", 2)
        return this.$router.push({
          path: '/document/form',
          query: {
            cid: item.fid,
            id: item.id
          }
        })
      },
      showGallery(item) {
        var images = []

        item.map(function (it) {
          images.push(it.replace('?x-oss-process=style/take', ''))
        })
        ImagePreview({
          closeOnPopstate: true,
          images: item
        })
      },
      onVideo(item) {
        this.showVideo = true
        this.video.url = item.video
        console.log('video', item.video)
      },
      // 基本
      onLoadBase() {
        const list = this.base
        list.loading = true

        if (list.refreshing) {
          list.items = []
          list.refreshing = false
        }

        var that = this

        fetchGet(`guest/config`, {
          guest_id: this.chat.id
        }).then((res) => {
          res.data.forEach((item) => {
            list.items.push(item)
            const configs = item.config;
            configs.forEach((c) => {
              if (c.value != 'null') {
                that.config[c.id] = c.value
              } else {
                that.config[c.id] = ''
              }
            })
          })

          list.loading = true
          list.refreshing = true
        })
      },
      // 保存数据
      saveInput(item) {
        let value = this.config[item.id]
        let form = {
          field: item.name,
          value,
          guest_id: this.chat.id,
          config_id: item.id,
        }
        console.log('form', form)
        fetchPost(`guest/save`, form).then((res) => {
          console.log('res', res)
          this.$notify({
            type: 'success',
            message: res.message
          })
        })
      },

      // 动态
      onLoadTimeline() {
        const list = this.timeline
        list.loading = true

        if (list.refreshing) {
          list.loading = false
          return false
        }

        fetchGet(`timeline/index`, {
          guest_id: this.chat.id
        }).then((res) => {
          console.log('time line', res)
          list.items = list.items.concat(res.data)
          list.loading = false
          list.refreshing = true
        })

        list.loading = false
        list.refreshing = true
      },

      // 单据上传
      onLoadForm() {
        const list = this.form
        list.loading = true

        if (list.refreshing) {
          list.loading = false
          return false
        }

        fetchGet(`fromdata/list`, {
          guest_id: this.chat.id
        }).then((res) => {
          res.data.forEach((item) => {
            list.items.push(item)
          })
          list.loading = false
          list.refreshing = true
        })

        list.loading = false
        list.refreshing = true
      },

      // 合同
      onLoadContract() {
        const list = this.contract
        list.loading = true

        if (list.refreshing) {
          list.loading = false
          return false
        }

        fetchGet(`contract/index`, {
          guest_id: this.chat.id
        }).then((res) => {
          res.data.forEach((item) => {
            list.items.push(item)
          })
          list.loading = false
          list.refreshing = true
        })

        list.loading = false
        list.refreshing = true
      },

      // 预约
      onLoadPre() {
        const list = this.pre
        list.loading = true

        if (list.refreshing) {
          list.loading = false
          return false
        }

        fetchGet(`pre/index`, {
          guest_id: this.chat.id
        }).then((res) => {
          res.data.forEach((item) => {
            list.items.push(item)
          })
          list.loading = false
          list.refreshing = true
        })

        list.loading = false
        list.refreshing = true
      },

      // 相册
      onLoadGallery() {
        const list = this.gallery
        list.loading = true

        if (list.refreshing) {
          list.loading = false
          return false
        }

        var params = {
          guest_id: this.chat.id,
        }

        this.$store.dispatch('getGallery', params).then((res) => {
          list.contracts = res.contract
          list.take = res.take

          list.loading = false
          list.refreshing = true
        })

        list.loading = false
        list.refreshing = true
      },

      // 更新
      onRefresh() {},

      // 返回上页
      onClickLeft() {
        history.go(-1)
      },

      // 获取企业认证
      getWorkCheck() {
        this.$toast.loading({
          message: '群获取中..',
          forbidClick: true,
          duration: 1,
        })
        wxJs.init(['checkJsApi', 'agentConfig']).then((wx) => {
          console.log('WxJs.init', wx)

          window.wx.checkJsApi({
            jsApiList: [
              'agentConfig',
              'sendChatMessage',
              'selectExternalContact',
              'getCurExternalChat',
            ],
            success: (res) => {
              this.$notify({
                message: '已获取微信config认证',
                type: 'primary',
              })
              console.log('wx.checkJsApi', res)
              this.agentInit()
            },
            error: (err) => {
              console.log('err', err)
            },
          })
        })
      },

      // 获取群信息
      async getChatId(chatId) {
        fetchGet(`guest/chat`, {
          chatId: chatId
        }).then(() => {
          this.isChat = true
          this.onLoadBase()
        })
      },

      // 获取应用参数
      async agentInit() {
        let that = this
        wxJs.agentInit(['getCurExternalChat', 'sendChatMessage']).then((cc) => {
          console.log('agentList', cc)

          window.wx.invoke('getCurExternalChat', {}, function (res) {
            if (res.err_msg == 'getCurExternalChat:ok') {
              let chatId = res.chatId //返回当前客户群的群聊ID
              localStorage.setItem('chatId', chatId)
              that.$notify({
                message: '已获取群ID成功',
                type: 'primary',
              })

              that.getChatId(chatId)
              that.$toast.clear()
            } else {
              that.$notify({
                message: '群ID获取失败',
                type: 'danger',
              })
              //错误处理
              console.log('wx.invoke', res.err_msg)
            }
          })
        })
      },
      shareMessageContract(item) {
        window.wx.invoke(
          'sendChatMessage', {
            msgtype: 'news',
            news: {
              link: item.contract_file,
              title: '存档合同查看',
              desc: '存档合同查看',
              imgUrl: item.sign_image,
            },
          },
          function (res) {
            console.log('res', res.err_msg)
            if (res.err_msg == 'sendChatMessage:ok') {
              //发送成功
              console.log('res', res.err_msg)
            }
          }
        )
      },
      shareSign(item) {
        var link =
          showUrl + '/sign?id=' + item.id + '&merchant_id=' + item.merchant_id
        window.wx.invoke(
          'sendChatMessage', {
            msgtype: 'news',
            news: {
              link: link,
              title: '邀请客户签名' + item.cate_name,
              desc: '邀请客户签名' + item.cate_name,
            },
          },
          function (res) {
            console.log('res', res.err_msg)
            if (res.err_msg == 'sendChatMessage:ok') {
              //发送成功
              console.log('res', res.err_msg)
            }
          }
        )
      },
    },
  }
</script>

<style lang="less" scoped>
  .image-list {
    width: 100%;
    height: 100px;
    overflow: hidden;
    overflow-y: scroll;
    position: relative;
  }

  .image-list img {
    margin: 0 5px;
  }

  .van-image-preview__image {
    img.van-image__img {
      background: #ffffff;
    }
  }
</style>
<style >
  #guest .van-tab{
    max-width:70px;
  }
  #guest .van-tabs__line{
    left:0 !important;
  }
</style>