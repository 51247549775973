<template>
  <div>
    <van-panel
      v-for="(item,index) in data"
      :key="index"
      :title="item.message.title"
      :desc="item.message.desc"
      :status="item.type + item.id"
    >
      <template #footer>
        <van-button size="small" type="danger" @click="share(item)">分享到会话</van-button>
      </template>
    </van-panel>
  </div>
</template>

<script>
import { fetchGet } from '@/utils/http.js'
export default {
  name: 'Share',
  data() {
    return {
      page: 1,
      data: []
    }
  },
  mounted() {
    this.initData()
  },
  created: function() {
    if (this.$route.params.page) {
      this.page = this.$route.params.page
      console.log(this.page)
    }
  },
  methods: {
    initData() {
      fetchGet(`share/index`, { page: this.page }).then(res => {
        let data = res.data
        this.data = data
        console.log('res', this.data)
      })
    },
    share(item) {
      console.log('item', item)
      let message = item.message
      window.wx.invoke(
        'sendChatMessage',
        {
          msgtype: 'news',
          news: {
            link: message.link,
            title: message.title,
            desc: message.desc,
            imgUrl: message.imgUrl
          }
        },
        function(res) {
          console.log('res', res.err_msg)
          if (res.err_msg == 'sendChatMessage:ok') {
            //发送成功
            console.log('res', res.err_msg)
          }
        }
      )
    }
  }
}
</script>