<template>
  <van-uploader
    v-model="fileList"
    :after-read="afterRead"
    :before-read="beforeRead"
    accept="video/*"
    multiple
    uploadIcon="video"
    :max-count="1"
  ></van-uploader>
</template>

<script>
export default {
  name: 'OneUploadVideo',
  props: {
    video: {
      type: String
    },
    'max-count': {
      type: Number
    }
  },
  data() {
    return {
      fileList: []
    }
  },
  methods: {
    beforeRead(file) {
      //上传之前校验
      if (file.type !== 'video/mp4') {
        this.$toast('仅支持MP4格式的视频')
        return false
      }
      return true
    },
    afterRead(file) {
      let params = new FormData()
      params.append('file', file.file)
      let config = {
        headers: {
          //添加请求头
          'Content-Type': 'multipart/form-data'
        }
      }
      var that = this
      that.$toast.loading({
        message: '视频上传中.',
        forbidClick: true,
        duration: process.env.NODE_ENV != 'development' ? 0 : 1000 * 1
      })

      this.$store
        .dispatch('getVideo', params, config)
        .then(res => {
          console.log('video', res)
          this.$emit('syncVideo', res.data.url)
          that.$toast.clear()
        })
        .cath(e => {
          console.log('e', e)
          that.$toast.clear()
        })
    }
  }
}
</script>

<style lang="less" scoped>
</style>