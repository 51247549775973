<template>
  <div style="background:#f1f1f1;">
    <van-cell-group>
      <van-cell>
        <template #title>
          <van-tag v-show="view.is_urgent != '0'" type="danger">紧急</van-tag>
          {{view.question}}
        </template>
      </van-cell>
      <van-cell title="发布人" :value="view.member_name" />
      <van-cell title="发布时间" :value="view.created_at | dateformat('YYYY-MM-DD')" />
      <van-cell title="类型" :value="view.type_name" />
      <van-cell title="责任人" :value="view.to_member_name" />
    </van-cell-group>

    <van-collapse v-model="activeNames">
      <van-collapse-item
        v-for="(item,index) in view.data"
        :key="index"
        :title="item.id"
        :name="index"
      >
        <template #title>
          <div>
            <van-tag v-show="item.is_urgent != '0'" type="danger">紧急</van-tag>
            &nbsp;
            {{item.created_at | dateformat('YYYY-MM-DD')}}
          </div>
        </template>
        <div>发布人：{{item.member_name}}</div>
        <div>责任人：{{item.to_member_name}}</div>
        <div
          v-if="item.pre_success_time != ''"
        >预计处理时间：{{item.pre_success_time | dateformat('YYYY-MM-DD')}}</div>
        {{item.question}}
      </van-collapse-item>
    </van-collapse>

    <div class="image-list">
      <van-image
        v-for="(image, index) in view.covers"
        :key="index"
        fit="cover"
        width="27vw"
        height="27vw"
        :src="image"
        @click="showGallery(view.covers)"
      >
        <template v-slot:loading>
          <van-loading type="spinner" size="20" />
        </template>
      </van-image>
    </div>

    <div class="bottom">
      <div style="margin:10px;">
        <van-button round block type="info" :url="'/complaint/repay?id='+id">回复</van-button>
      </div>
    </div>
  </div>
</template>

<script>
import storage from 'store'

import { fetchGet, fetchPost } from '@/utils/http.js'
//import wxJs from '@/utils/wx-sdk'
import { ImagePreview } from 'vant'
export default {
  components: {
    [ImagePreview.Component.name]: ImagePreview.Component,
  },
  data() {
    return {
      id: 0,
      activeNames: [0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10],
      view: {},
      form: {
        question: '',
        to_member_id: '',
        cover: [],
      },
      columns: [],
      loading: true,
      showPicker: false,
      showForm: false,
    }
  },

  mounted: function () {
    this.id = this.$route.query.id
    if (this.id) {
      this.$store.dispatch('getComplaintId', this.id)
      this.getView()
      this.getStaff()
    } else {
      return this.$route.push({ path: '/' })
    }
  },
  methods: {
    showGallery(item) {
      ImagePreview({ closeOnPopstate: true, images: item })
    },
    getView() {
      var id = this.id
      this.$store.dispatch('getComplaintView', { id }).then((res) => {
        console.log('res', res)
        this.view = res.data
      })
    },
    onSelect(option) {
      this.showShare = false
      console.log('option', option)
      this.value = option.userid
    },
    getStaff() {
      var chat = storage.get('GET_CHAT')
      console.log('chat', chat)
      fetchGet(`guest/staff`, { id: chat.id }).then((item) => {
        this.options = item.data
        console.log('staff', item)
        this.loading = false
      })
    },
    submit() {
      var chat = storage.get('GET_CHAT')
      console.log('chat', chat)
      this.form.parent_id = this.view.id
      this.form.to_member_id = this.view.to_member_id

      fetchPost(`complaint/create`, this.form).then((res) => {
        console.log('res', res)
        this.$dialog
          .alert({
            title: '完成',
            message: res.message,
          })
          .then(() => {
            if (res.code == '200') {
              window.wx.closeWindow()
            }
          })
      })
    },
  },
}
</script>


<style lang="less" scoped>
.image-list {
  width: 100%;
  height: 100px;
  overflow-x: scroll;
  position: relative;
}
.image-list img {
  margin: 0 5px;
}
.bottom {
  position: fixed;
  bottom: 0;
  left: 0;
  width: 100%;
  background: #fff;
  border-top: 1px #f1f1f1 solid;
}
</style>