import { fetchPost } from './http.js' //http配置文件

/**
 * 在需要调用企业微信接口时才需要使用该功能
 * 每次使用接口页面前使用
 */
export default {
  async init(apiList = [], url) {
    return new Promise((resolve, reject) => {
      let thatUrl = location.href
      console.log('config url', thatUrl)
      fetchPost('/wechat/js-sdk', {
        url: url || thatUrl, //配置签名的URL
        apiList,
      }).then((jres) => {
        let config = jres.data

        if (config.appId) {
          window.wx.config({
            beta: true,
            debug: false,
            appId: config.appId, // 必填，企业号的唯一标识，此处填写企业号corpid
            timestamp: config.timestamp, // 必填，生成签名的时间戳
            nonceStr: config.nonceStr, // 必填，生成签名的随机串
            signature: config.signature, // 必填，签名，见附录1
            jsApiList: apiList, // 必填，需要使用的JS接口列表，所有JS接口列表见附录2
          })

          window.wx.ready(() => {
            resolve(window.wx)
          })
          window.wx.error(function(res) {
            console.log('wx.error', res)
          })
        } else {
          console.log('wx-sdk reject:', jres)
          reject(jres)
        }
      })
    })
  },

  agentInit(apiList = [], url) {
    //需要使用的api列表
    // const u = navigator.userAgent.toLowerCase(),
    //   isIOS = u.indexOf('iphone') > -1 //判断是否是ios微信
    let thatUrl = location.href
    console.log('agent url', thatUrl)
    return new Promise((resolve, reject) => {
      fetchPost('/wechat/agent-config', {
        url: url || thatUrl, //配置签名的URL
        apiList,
      }).then((jres) => {
        let config = jres.data

        if (config.corpid) {
          window.wx.agentConfig({
            agentid: config.agentid,
            corpid: config.corpid, // 必填，企业号的唯一标识，此处填写企业号corpid
            timestamp: config.timestamp, // 必填，生成签名的时间戳
            nonceStr: config.nonceStr, // 必填，生成签名的随机串
            signature: config.signature, // 必填，签名，见附录1
            jsApiList: apiList, // 必填，需要使用的JS接口列表，所有JS接口列表见附录2
            success: (res) => {
              resolve(window.wx, res)
            },
            fail: (res) => {
              console.log('agentConfig fail ', res)
              reject(jres)
            },
            complete: (res) => {
              console.log('complete', res)
            },
          })
        } else {
          reject(jres)
        }
      })
    })
  },
}
