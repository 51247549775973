<template>
  <div id="sign_name">
    <van-nav-bar
      title="审核签名"
      left-arrow
      left-text="返回"
      @click-left="onClickLeft"
    />
    <VueSignaturePad
      id="signature"
      width="98%"
      height="500px"
      ref="signaturePad"
    />
    <div style="margin:15px;">
      <van-row>
        <van-col span="16">
          <van-button type="primary" @click="save"
            >保存签名并发送给客户</van-button
          >
        </van-col>
        <van-col span="8">
          <van-button type="default" @click="undo">重新签名</van-button>
        </van-col>
      </van-row>
    </div>
  </div>
</template>

<script>
import { fetchGet } from '@/utils/http.js'

export default {
  name: 'ContractSign',
  data() {
    return {
      id: '',
    }
  },

  mounted: function() {
    this.id = this.$route.query.id
    this.getCate()
  },
  methods: {
    undo() {
      this.$refs.signaturePad.undoSignature()
    },
    save() {
      const { isEmpty, data } = this.$refs.signaturePad.saveSignature()
      console.log(isEmpty)
      console.log(data)

      this.$dialog
        .confirm({
          title: '生成合同',
          message: '你审核合同无误，确定发送给客户吗？',
        })
        .then(() => {
          // on confirm
          this.$router.push({ name: 'ContractView', query: { id: this.id } })
        })
        .catch(() => {
          // on cancel
        })
    },
    // 获取商户详情
    getCate() {
      fetchGet(`contract/view`, { id: this.id }).then((res) => {
        console.log('view', res.data)
        this.title = res.data.title
        this.contract = res.data.contract
        this.fields = res.data.fields
      })
    },
    onClickLeft() {
      history.go(-1)
    },
  },
}
</script>

<style scoped>
#sign_name {
  padding: 1%;
}
#signature {
  border: double 3px transparent;
  border-radius: 5px;
  background-image: linear-gradient(white, white),
    radial-gradient(circle at top left, #4bc5e8, #9f6274);
  background-origin: border-box;
  background-clip: content-box, border-box;
}
</style>
