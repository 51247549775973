<template>
  <div id="take-form">
    <van-nav-bar
      :title="pageTitle"
      left-arrow
      left-text="返回"
      @click-left="onClickLeft"
    />

    <van-steps :active="active">
      <van-step>查看合同</van-step>
      <van-step>完善资料</van-step>
      <van-step>店长审核</van-step>
      <van-step>客户签名</van-step>
    </van-steps>

    <van-form @submit="onSubmit">
      <div class="form-field" v-for="(item, index) in fields" :key="index">
        <van-cell-group>
          <one-field-index
            :label="item.title"
            :v-model="form.field_content"
            :placeholder="item.title"
          />
        </van-cell-group>
      </div>
      <van-field name="form.maps" label="图纸上传">
        <template #input>
          <van-uploader v-model="form.maps" />
        </template>
      </van-field>

      <div style="margin: 16px;">
        <van-button round block type="info" native-type="submit"
          >提交审核</van-button
        >
      </div>
    </van-form>
  </div>
</template>

<script>
import { fetchGet, fetchPost } from '@/utils/http.js'
import OneFieldIndex from '@/components/one-field-index/index.vue'

export default {
  name: 'ContractForm',
  components: { OneFieldIndex },
  data() {
    return {
      active: 1,
      pageTitle: '',
      title: '',
      cid: '',
      contract: {},
      fields: [],
      form: {
        field_content: [],
        maps: [],
      },
    }
  },

  mounted: function() {
    this.cid = this.$route.query.id
    this.title = this.$route.query.title
    this.pageTitle = '完善' + this.title
    this.getCate()
  },
  methods: {
    submit() {},
    // 异步校验函数返回 Promise
    asyncValidator(val) {
      return new Promise((resolve) => {
        this.$toast.loading('验证中...')

        setTimeout(() => {
          this.$toast.clear()
          resolve(/\d{6}/.test(val))
        }, 1000)
      })
    },

    // 发起合同签约
    onSubmit() {
      this.$dialog
        .confirm({
          title: '发起合同审核',
          message: '你确定要发起该合同审核吗？',
        })
        .then(() => {
          // TODO on confirm
          this.onCreate()
        })
        .catch(() => {
          // on cancel
          this.$toast.fail('已取消')
        })
    },

    onCreate() {
      fetchPost(`contract/create`, this.form).then((res) => {
        console.log('res', res)
        this.$router.push({ name: 'ContractCheck', query: { id: res.data.id } })
      })
      this.$toast.success('发起合同完成')
    },

    // 出错提示
    onFailed(errorInfo) {
      console.log('failed', errorInfo)
    },

    // 返回上页
    onClickLeft() {
      history.go(-1)
    },
    init() {
      this.getCate()
    },
    // 获取商户详情
    getCate() {
      fetchGet(`contract-cate/view`, { id: this.cid }).then((res) => {
        console.log('view', res.data)
        this.title = res.data.title
        this.contract = res.data.contract
        this.fields = res.data.fields
      })
    },
  },
}
</script>
