<template>
  <div id="take-form">
    <van-nav-bar
      :title="pageTitle"
      left-arrow
      left-text="返回"
      @click-left="onClickLeft"
    />

    <van-swipe>
      <van-swipe-item v-for="(image, index) in covers" :key="index">
        <img
          v-lazy="image"
          :data-index="index"
          @click="onShowImage(index)"
          style="width:100%;"
        />
      </van-swipe-item>
    </van-swipe>

    <div class="bottom">
      <div style="margin:10px;">
        <van-button
          round
          block
          type="info"
          :to="{ name: 'GuestSign', query: { id: id } }"
          >签名</van-button
        >
      </div>
    </div>
  </div>
</template>

<script>
import { fetchGet } from '@/utils/http.js'

import { ImagePreview } from 'vant'

export default {
  name: 'ContractCreate',
  components: {},
  data() {
    return {
      id: 0,
      title: '',
      pageTitle: '',
      message: '',
      contract: {},
      covers: [
        'http://img.yikeyiqun.cn/images/2020/05/02/image_1588407330_hBazXM18.png',
        'http://img.yikeyiqun.cn/images/2020/05/02/image_1588407330_kFvv7lKO.png',
        'http://img.yikeyiqun.cn/images/2020/05/02/image_1588407331_FMkDmGuU.png',
        'http://img.yikeyiqun.cn/images/2020/05/02/image_1588407331_pwfwfAnO.png',
      ],
    }
  },

  mounted: function() {
    this.cid = this.$route.query.id
    this.pageTitle = '查看合同'
    this.getCate()
  },
  methods: {
    submit() {},
    // 异步校验函数返回 Promise
    asyncValidator(val) {
      return new Promise((resolve) => {
        this.$toast.loading('验证中...')

        setTimeout(() => {
          this.$toast.clear()
          resolve(/\d{6}/.test(val))
        }, 1000)
      })
    },
    onDownload() {
      this.$toast.success('下载中..')
    },
    onSubmit() {},

    onShowImage(index) {
      ImagePreview({
        images: this.covers,
        startPosition: index,
        onClose() {
          // do something
        },
      })
    },
    // 出错提示
    onFailed(errorInfo) {
      console.log('failed', errorInfo)
    },

    // 返回上页
    onClickLeft() {
      history.go(-1)
    },
    // 获取商户详情
    getCate() {
      fetchGet(`contract-cate/view`, { id: this.cid }).then((res) => {
        console.log('view', res.data)
        this.title = res.data.title
        this.contract = res.data.contract
        this.covers = this.contract.covers
        this.fields = res.data.fields
      })
    },
  },
}
</script>

<style scoped>
.bottom {
  position: fixed;
  bottom: 0;
  left: 0;
  width: 100%;
  background: #fff;
  border-top: 1px #f1f1f1 solid;
}
</style>
