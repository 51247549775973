import storage from 'store'
import { fetchPost, fetchGet } from '@/utils/http.js'
import {
  GET_MERCHANT,
  GET_MERCHANT_ID,
  GET_CHAT_ID,
  PRE_CATE_PID,
  GET_CHAT,
  PRE_CATE_ID,
  PRE_CATE,
  PRE_CATE_INFO,
  PRE_ID,
  PRE_INFO,
  PRE_CREATE,
  GET_MENU,
} from '../mutation-types'

const state = {
  // 商户信息
  merchantId: 0,
  merchant: {},

  // 群信息
  chatId: '',
  chat: {},

  // 预约
  pre_cate_id: 0,
  pre_cate_pid: 0,
  pre_cate: {},
  pre_cate_info: {},
  pre_id: 0,
  pre_info: {},
  get_menu: {},
}

const mutations = {
  [GET_MERCHANT_ID]: (state, id) => {
    state.merchantId = id
    storage.set(GET_MERCHANT_ID, id)
  },
  [GET_MERCHANT]: (state, res) => {
    state.merchant = res
    storage.set(GET_MERCHANT, res)
  },
  [GET_CHAT_ID]: (state, id) => {
    state.chatId = id
    storage.set(GET_CHAT_ID, id)
  },
  [GET_CHAT]: (state, res) => {
    state.chat = res
    storage.set(GET_CHAT, res)
  },
  [PRE_CATE_PID]: (state, id) => {
    state.pre_cate_pid = id
  },
  [PRE_CATE_ID]: (state, id) => {
    state.pre_cate_id = id
  },
  [PRE_CATE]: (state, res) => {
    state.pre_cate = res
  },
  [PRE_CATE_INFO]: (state, res) => {
    state.pre_cate_info = res
  },
  [PRE_ID]: (state, id) => {
    state.pre_id = id
  },
  [PRE_INFO]: (state, res) => {
    state.pre_info = res
  },
  [PRE_CREATE]: (state, res) => {
    state.pre_info = res
  },
  [GET_MENU]: (state, res) => {
    state.get_menu = res
  },
}

const actions = {
  setMerchantId({ commit }, id) {
    return commit(GET_MERCHANT_ID, id)
  },
  setMerchant({ commit }) {
    return new Promise((resolve, reject) => {
      let merchant_id = state.merchantId
      fetchPost(`merchant/config`, { merchant_id })
        .then((res) => {
          commit(GET_MERCHANT, res.data)
          resolve(res.data)
        })
        .catch((e) => {
          reject(e)
        })
    })
  },
  setChatId({ commit }, id) {
    return commit(GET_CHAT_ID, id)
  },
  getMenu({ commit }) {
    return new Promise((resolve, reject) => {
      fetchGet(`guest/menu`)
        .then((res) => {
          commit(GET_MENU, res.data)
          resolve(res.data)
        })
        .catch((e) => {
          reject(e)
        })
    })
  },
  getChat({ commit }) {
    return new Promise((resolve, reject) => {
      fetchGet(`guest/chat`, { chatId: state.chatId })
        .then((res) => {
          console.log('getChat', res, state.chatId)
          commit(GET_CHAT, res.data)
          resolve(res)
        })
        .catch((e) => {
          reject(e)
        })
    })
  },
  syncChant() {
    return new Promise((resolve, reject) => {
      fetchPost(`wechat/chat`, { id: state.chatId })
        .then((res) => {
          resolve(res)
        })
        .catch((e) => {
          reject(e)
        })
    })
  },
  clearCache() {
    return new Promise((resolve, reject) => {
      fetchGet(`wechat/chat`)
        .then((res) => {
          resolve(res)
        })
        .catch((e) => {
          reject(e)
        })
    })
  },
  getPreCatePid({ commit }, id) {
    return commit(PRE_CATE_PID, id)
  },
  getPreCateId({ commit }, id) {
    return commit(PRE_CATE_ID, id)
  },
  getPreCate({ commit }) {
    return new Promise((resolve, reject) => {
      fetchGet(`pre-cate/index`, { pid: state.pre_cate_pid })
        .then((res) => {
          commit(PRE_CATE, res.data)
          resolve(res.data)
        })
        .catch((e) => {
          reject(e)
        })
    })
  },
  getPreCateInfo({ commit }) {
    return new Promise((resolve, reject) => {
      fetchGet(`pre-cate/view`, { id: state.pre_cate_id })
        .then((res) => {
          commit(PRE_CATE_INFO, res.data)
          resolve()
        })
        .catch((e) => {
          reject(e)
        })
    })
  },
  createPre({ commit }, data) {
    return new Promise((resolve, reject) => {
      fetchPost(`pre/create`, data)
        .then((res) => {
          commit(PRE_INFO, res.data)
          resolve(res)
        })
        .catch((e) => {
          reject(e)
        })
    })
  },
}

export default {
  namespace: true,
  state,
  mutations,
  actions,
}
