import storage from 'store'
import { fetchPost } from '@/utils/http.js'
import { ACCESS_TOKEN, USER_INFO } from '../mutation-types'

const state = {
  access_token: '',
  userInfo: {},
}

const mutations = {
  [ACCESS_TOKEN]: (state, id) => {
    state.access_token = id
    storage.set(ACCESS_TOKEN, id)
  },
  [USER_INFO]: (state, res) => {
    state.userInfo = res
    storage.set(USER_INFO, res)
  },
}

const actions = {
  getAccessToken({ commit }, id) {
    return commit(ACCESS_TOKEN, id)
  },
  Login({ commit }, params) {
    return new Promise((resolve, reject) => {
      fetchPost(`wechat/login`, params)
        .then((res) => {
          commit(USER_INFO, res.data)
          if (res.data.access_token) {
            commit(ACCESS_TOKEN, res.data.access_token)
          }
          resolve(res)
        })
        .catch((e) => {
          reject(e)
        })
    })
  },
}

export default {
  namespace: true,
  state,
  mutations,
  actions,
}
