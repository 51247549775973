import Vue from 'vue'
import VueRouter from 'vue-router'
import Home from '../views/Home.vue'
import PreIndex from '@/views/pre/Index.vue'
import PreCreate from '@/views/pre/Create.vue'
import PreSelect from '@/views/pre/Select.vue'

import SignIndex from '@/views/sign/Index.vue'
import SignCreate from '@/views/sign/Create.vue'
import Sign from '@/views/sign/Sign.vue'

import ContractIndex from '@/views/contract/Index.vue'
import ContractCreate from '@/views/contract/Create.vue'
import ContractSelect from '@/views/contract/Select.vue'
import ContractSign from '@/views/contract/Sign.vue'
import ContractForm from '@/views/contract/Form.vue'
import ContractCheck from '@/views/contract/Check.vue'
import ContractView from '@/views/contract/View.vue'
import GuestSign from '@/views/contract/GuestSign.vue'

import TakeIndex from '@/views/take/Index.vue'
import TakeCreate from '@/views/take/Create.vue'
import TakeSelect from '@/views/take/Select.vue'

import Guest from '@/views/Guest.vue'
import Document from '@/views/document/select.vue'
import DocumentForm from '@/views/document/form.vue'
import MessageShare from '@/views/message/Share.vue'
import Complaint from '@/views/complaint/Complaint.vue'
import ComplaintCreate from '@/views/complaint/Create.vue'
import ComplaintView from '@/views/complaint/View.vue'
import ComplaintRepay from '@/views/complaint/Repay.vue'

import Auth from '@/views/Auth.vue'
//import Map from '@/views/Map.vue'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'Home',
    component: Home,
  },
  {
    path: '/pre',
    name: 'preIndex',
    component: PreIndex,
  },
  {
    path: '/auth',
    name: 'auth',
    component: Auth,
  },
  {
    path: '/pre/create',
    name: 'preCreate',
    component: PreCreate,
  },
  {
    path: '/pre/select',
    name: 'preSelect',
    component: PreSelect,
  },
  {
    path: '/take',
    name: 'takeIndex',
    component: TakeIndex,
  },
  {
    path: '/take/create',
    name: 'takeCraete',
    component: TakeCreate,
  },
  {
    path: '/take/select',
    name: 'takeSelect',
    component: TakeSelect,
  },
  {
    path: '/sign',
    name: 'signIndex',
    component: SignIndex,
  },
  {
    path: '/sign/sign',
    name: 'Sign',
    component: Sign,
  },
  {
    path: '/sign/create',
    name: 'signCreate',
    component: SignCreate,
  },
  {
    path: '/contract',
    name: 'ContractIndex',
    component: ContractIndex,
  },
  {
    path: '/contract/sign',
    name: 'ContractSign',
    component: ContractSign,
  },
  {
    path: '/contract/create',
    name: 'ContractCreate',
    component: ContractCreate,
  },
  {
    path: '/contract/select',
    name: 'ContractSelect',
    component: ContractSelect,
  },
  {
    path: '/contract/form',
    name: 'ContractForm',
    component: ContractForm,
  },
  {
    path: '/contract/check',
    name: 'ContractCheck',
    component: ContractCheck,
  },
  {
    path: '/contract/view',
    name: 'ContractView',
    component: ContractView,
  },
  {
    path: '/contract/guest-sign',
    name: 'GuestSign',
    component: GuestSign,
  },
  {
    path: '/guest',
    name: 'Guest',
    component: Guest,
  },
  {
    path: '/document/select',
    name: 'Document',
    component: Document,
  },
  {
    path: '/document/form',
    name: 'DocumentForm',
    component: DocumentForm,
  },

  {
    path: '/complaint',
    name: 'Complaint',
    component: Complaint,
  },
  {
    path: '/complaint/create',
    name: 'ComplaintCreate',
    component: ComplaintCreate,
  },
  {
    path: '/complaint/view',
    name: 'ComplaintView',
    component: ComplaintView,
  },
  {
    path: '/complaint/repay',
    name: 'ComplaintRepay',
    component: ComplaintRepay,
  },
  {
    path: '/message/share',
    name: 'MessageShare',
    component: MessageShare,
  },

  {
    path: '/map',
    name: 'Map',
    component: Map,
  },
]

const router = new VueRouter({
  mode: 'history',
  routes,
})

export default router
