<template>
  <div class="pre-index">
    <van-nav-bar title="预约列表" left-arrow left-text="返回" @click-left="onClickLeft" />

    <van-empty description="暂无数据" />
  </div>
</template>

<script>
export default {
  name: 'preIndex',
  data() {
    return {
      // 返回上一页
      onClickLeft() {
        history.go(-1)
      }
    }
  },
  methods: {}
}
</script>

<style>
</style>