<template>
  <div id="take-form">
    <van-nav-bar title="回复" left-arrow left-text="返回" @click-left="onClickLeft" />

    <van-field
      v-model="message"
      rows="5"
      autosize
      type="textarea"
      maxlength="5000"
      placeholder="请输入跟进内容"
      show-word-limit
    />

    <van-field
      v-if="is_only"
      v-model="type"
      label="类型"
      readonly
      clickable
      is-link
      @click="showType = true"
      placeholder="请输入跟进类型"
    />

    <van-cell v-if="is_only" center title="是否紧急">
      <template #right-icon>
        <van-switch v-model="is_urgent" size="24" />
      </template>
    </van-cell>

    <van-field
      v-if="is_only"
      readonly
      clickable
      name="picker"
      :value="value"
      label="指定责任人"
      placeholder="点击选择群中同事"
      @click="showShare = true"
    />
    <van-field
      readonly
      clickable
      name="datetimePicker"
      :value="pre_success_time"
      label="预计完成时间"
      placeholder="点击选择时间"
      @click="showPicker = true"
    />
    <van-popup v-model="showPicker" position="bottom">
      <van-datetime-picker
        type="date"
        :filter="filter"
        :formatter="formatter"
        :min-date="minDate"
        @confirm="confirmPicker"
        @cancel="showPicker = false"
      />
    </van-popup>

    <van-field name="uploader">
      <template #input>
        <one-uploader :data="covers" :total="9" @syncdata="chooseImage" />
      </template>
    </van-field>

    <div style="margin: 30px;">
      <van-button round block type="info" @click="submit()" native-type="submit">提交</van-button>
    </div>
    <van-share-sheet v-model="showShare" @select="onSelect" :options="options" title="选择责任人" />
    <van-action-sheet
      v-model="showType"
      :actions="typeOptions"
      cancel-text="取消"
      close-on-click-action
      @select="selectType"
      @cancel="showType = false"
    />
  </div>
</template>

<script>
import moment from 'moment'
import storage from 'store'
import { fetchGet, fetchPost } from '@/utils/http.js'
import OneUploader from '@/components/one-uploader/index.vue'
import wxJs from '@/utils/wx-sdk'

export default {
  name: 'TakeCreate',
  data() {
    return {
      id: 0,
      minDate: new Date(),
      is_only: false,
      item: {},
      showShare: false,
      showType: false,
      typeOptions: [],
      options: [{ name: '微信', icon: 'wechat' }],
      isChat: false,
      chatInfo: {},
      message: '',
      is_urgent: 0,
      covers: [],
      value: '',
      type: '',
      columns: [],
      loading: true,
      showPicker: false,
      showTime: false,
      pre_success_time: moment(new Date()).format('YYYY-MM-DD'),
      location: {}
    }
  },
  components: {
    OneUploader
  },
  mounted: function() {
    this.getType()
    this.id = this.$route.query.id
    this.getView()
    this.getWorkCheck()
    this.getStaff()
  },
  methods: {
    onSelect(option) {
      this.showShare = false
      console.log('option', option)
      this.value = option.userid
      this.to_member_id = option.userid
    },
    selectType(aciton, index) {
      console.log(aciton, index)
      this.type = aciton.name
      this.showType = false
    },
    getView() {
      this.$store.dispatch('getComplaintView', { id: this.id }).then(res => {
        console.log('view', res.data)
        var data = res.data
        this.item = data
        this.type = data.type_name
        this.is_urgent = data.is_urgent ? true : false
        this.to_member_id = data.to_member_id
        this.value = data.to_member_name
        this.pre_success_time = data.pre_success_time

        // 判断自己是否是责任人
        let work_user = JSON.parse(sessionStorage.getItem('work_user'))
        console.log('work_user', work_user.member.username, data.to_member_id)
        if (
          work_user.member.username.toString() == data.to_member_id.toString()
        ) {
          this.is_only = true
        } else {
          this.is_only = false
        }
      })
    },
    getType() {
      this.$store.dispatch('getComplaintTypeList').then(res => {
        console.log('type', res.data)
        this.typeOptions = res.data
      })
    },
    getStaff() {
      var chat = storage.get('GET_CHAT')
      console.log('chat', chat)
      fetchGet(`guest/staff`, { id: chat.id }).then(item => {
        this.options = item.data
        console.log('staff', item)
        this.loading = false
      })
    },
    chooseImage(res) {
      console.log('chooseImage', res)
      this.covers.push(res)
    },
    onConfirm(value) {
      this.pre_success_time = value
      this.showPicker = false
    },
    // 获取企业认证
    getWorkCheck() {
      wxJs.init(['checkJsApi', 'agentConfig', 'chooseImage']).then(wx => {
        console.log('WxJs.init', wx)

        window.wx.checkJsApi({
          jsApiList: [
            'agentConfig',
            'sendChatMessage',
            'selectExternalContact',
            'getCurExternalChat'
          ],
          success: res => {
            this.$notify({
              message: '已获取微信config认证',
              type: 'primary'
            })
            console.log('wx.checkJsApi', res)
            this.agentInit()
            wx.getLocation({
              type: 'wgs84',
              success: function(lres) {
                this.location = lres
                console.log('定位:', lres)
              }
            })
          },
          error: err => {
            console.log('err', err)
          }
        })
      })
    },

    // 获取应用参数
    async agentInit() {
      wxJs
        .agentInit(['getCurExternalChat', 'sendChatMessage', 'chooseImage'])
        .then(cc => {
          console.log('agentList', cc)
        })
    },

    submit() {
      var chat = storage.get('GET_CHAT')
      console.log('chat', chat)
      fetchPost(`complaint/create`, {
        covers: this.covers,
        is_urgent: this.is_urgent ? 1 : 0,
        guest_id: chat.id,
        to_member_id: this.to_member_id,
        question: this.message,
        type_name: this.type,
        parent_id: this.id,
        pre_success_time: this.pre_success_time
      }).then(res => {
        console.log('res', res)
        this.$dialog
          .alert({
            title: '完成',
            message: res.message
          })
          .then(() => {
            return this.$router.push({ path: '/' })
          })
      })
    },
    // 异步校验函数返回 Promise
    asyncValidator(val) {
      return new Promise(resolve => {
        this.$toast.loading('验证中...')

        setTimeout(() => {
          this.$toast.clear()
          resolve(/\d{6}/.test(val))
        }, 1000)
      })
    },

    // 返回上页
    onClickLeft() {
      history.go(-1)
    },

    // 确认选择的时间
    confirmPicker(val) {
      console.log('time', val)
      this.pre_success_time = moment(val).format('YYYY-MM-DD')
      this.showPicker = false
    },
    filter(type, options) {
      if (type === 'minute') {
        return options.filter(option => option % 10 === 0)
      }
      return options
    },

    // 选项格式化函数
    formatter(type, value) {
      if (type === 'year') {
        return `${value}年`
      } else if (type === 'month') {
        return `${value}月`
      } else if (type === 'day') {
        return `${value}日`
      } else if (type === 'hour') {
        return `${value}时`
      } else if (type === 'minute') {
        return `${value}分`
      } else if (type === 'second') {
        return `${value}秒`
      }
      return value
    }
  }
}
</script>

<style scoped>
</style>