export const GET_MERCHANT_ID = 'GET_MERCHANT_ID'
export const GET_MERCHANT = 'GET_MERCHANT'

export const GET_CHAT_ID = 'GET_CHAT_ID'
export const GET_CHAT = 'GET_CHAT'
export const GET_MENU = 'GET_MENU'

// 预约
export const PRE_CATE_PID = 'PRE_CATE_PID'
export const PRE_CATE_ID = 'PRE_CATE_ID'
export const PRE_CATE = 'PRE_CATE'
export const PRE_CATE_INFO = 'PRE_CATE_INFO'
export const PRE_ID = 'PRE_ID'
export const PRE_INFO = 'PRE_INFO'
export const PRE_CREATE = 'PRE_CREATE'

// 会员
export const ACCESS_TOKEN = 'ACCESS_TOKEN'
export const USER_INFO = 'USER_INFO'

// 拍照
export const TAKE_CATE_PID = 'TAKE_CATE_PID'
export const TAKE_CATE_ID = 'TAKE_CATE_ID'
export const TAKE_CATE = 'TAKE_CATE'
export const TAKE_CATE_VIEW = 'TAKE_CATE_VIEW'
export const TAKE_ID = 'TAKE_ID'
export const TAKE_VIEW = 'TAKE_VIEW'
export const TAKE_CREATE = 'TAKE_CREATE'
export const TAKE_VIDEO = 'TAKE_VIDEO'

// 签字
export const SIGN = 'SIGN'
export const SIGN_ID = 'SIGN_ID'
export const SIGN_INFO = 'SIGN_INFO'
export const SIGN_CREATE = 'SIGN_CREATE'

// 合同
export const CONTARACT_CATE_PID = 'CONTRACT_CATE_PID'
export const GALLERY = 'GALLERY'

// 跟进
export const COMPLAINT_CREATE = 'COMPLAINT_CREATE'
export const COMPLAINT_ID = 'COMPLAINT_ID'
export const COMPLAINT = 'COMPLAINT'
export const COMPLAINT_INFO = 'COMPLAINT_INFO'
export const COMPLAINT_TYPE_LIST = 'COMPLAINT_TYPE_LIST'
