<template>
  <div id="take-form">
    <!-- 载入 -->
    <van-overlay :show="!isChat">
      <div class="wrapper">
        <div class="block">
          <van-loading size="36px" type="spinner" color="#1989fa" vertical
            >群资料获取中.</van-loading
          >
        </div>
      </div>
    </van-overlay>

    <van-nav-bar
      :title="pageTitle"
      left-arrow
      left-text="返回"
      @click-left="onClickLeft"
    />

    <van-swipe>
      <van-swipe-item v-for="(image, index) in covers" :key="index">
        <img
          v-lazy="image"
          :data-index="index"
          @click="onShowImage(index)"
          style="width:100%;"
        />
      </van-swipe-item>
    </van-swipe>
    <van-divider color="#ff3300"
      >合同可以左右滑动，点击合同可放大拉伸显示。</van-divider
    >

    <p style="margin-bottom:60px;">&nbsp;</p>

    <div class="bottom">
      <div style="margin:10px;">
        <van-button
          round
          block
          type="info"
          :disabled="!isChat"
          @click="onCreate"
          >下一步</van-button
        >
      </div>
    </div>
  </div>
</template>

<script>
import storage from 'store'
import { fetchGet, fetchPost } from '@/utils/http.js'
import { contractUrl } from '@/utils/params.js'
import { jsUrl } from '@/utils/params.js'

import { ImagePreview } from 'vant'

export default {
  name: 'ContractCreate',
  components: {},
  data() {
    return {
      chatInfo: {},
      chatId: '',
      isChat: false,
      active: 0,
      cid: 0,
      title: '',
      pageTitle: '',
      message: '',
      form: {
        contract_template_id: 0,
        guest_id: '',
        member_id: '',
        cate_id: 0,
      },
      contract: {},
      covers: [],
      fields: [],
      isEdit: false,
      uploader: [{ url: 'https://img.yzcdn.cn/vant/leaf.jpg' }],
    }
  },

  mounted: function() {
    this.cid = this.$route.query.cid
    this.title = this.$route.query.title
    this.pageTitle = this.title + '签名'
    this.getChatId()
  },
  methods: {
    // 获取群信息
    getChatId() {
      var chat = storage.get('GET_CHAT')
      fetchGet(`guest/chat`, { chatId: chat.chat_id }).then((res) => {
        this.chatInfo = res.data
        console.log('chatInfo', res.data)
        this.isChat = true
        this.getCate()
      })
    },
    // 发起合同签约
    onSubmit() {
      this.$dialog
        .confirm({
          title: '发起合同',
          message: '你确定需要填写该合同内容吗？',
        })
        .then(() => {
          // TODO on confirm
        })
        .catch(() => {
          // on cancel
          this.$toast.fail('已取消')
        })
    },

    onCreate() {
      fetchPost(`contract/create`, this.form).then((res) => {
        console.log('res', res)

        if (res.data.id) {
          location.href =
            contractUrl +
            '?id=' +
            res.data.id +
            '&cate_id=' +
            this.cid +
            '&guest_id=' +
            this.chatInfo.id +
            '&merchant_id=' +
            res.data.merchant_id +
            '&jsUrl='+
            jsUrl
        } else {
          this.$toast.fail(res.message)
        }
      })
      this.$toast.success('发起合同完成')
    },

    onShowImage(index) {
      ImagePreview({
        images: this.covers,
        startPosition: index,
        onClose() {
          // do something
        },
      })
    },

    // 返回上页
    onClickLeft() {
      history.go(-1)
    },

    getMerchantId() {
      let merchant_id = localStorage.getItem('merchant_id')
      if (!merchant_id) {
        merchant_id = 2
      }
      return merchant_id
    },

    // 获取商户详情
    getCate() {
      fetchGet(`contract-cate/view`, { id: this.cid }).then((res) => {
        console.log('view', res.data)
        this.title = res.data.title
        this.pageTitle = res.data.title + '合同'
        this.contract = res.data.contract
        this.covers = this.contract.covers
        this.fields = res.data.fields
        this.form.merchant_id = this.getMerchantId()
        this.form.contract_template_id = this.contract.id
        this.form.cate_id = res.data.id
        this.form.guest_id = this.chatInfo.id
        console.log('form', this.form)
      })
    },
  },
}
</script>

<style scoped>
.bottom {
  position: fixed;
  bottom: 0;
  left: 0;
  width: 100%;
  background: #fff;
  border-top: 1px #f1f1f1 solid;
}
</style>
