<template>
  <div class="sign-index">
    <van-nav-bar title="签名列表" left-arrow left-text="返回" @click-left="onClickLeft" />

    <van-list v-model="loading" :finished="finished" finished-text="没有更多了" @load="onLoad">
      <van-cell v-for="item in list" :key="item" :title="item" />
    </van-list>
    <van-empty v-show="noData" description="暂无数据" />
  </div>
</template>

<script>
import storage from 'store'
export default {
  name: 'signIndex',
  data() {
    return {
      page: 1,
      list: [],
      loading: false,
      finished: false,
      noData: false, // 如果没有数据，显示暂无数据
      isLoading: false // 下拉的加载图案
    }
  },

  mounted() {},
  created: function() {
    if (this.$route.params.page) {
      this.page = this.$route.params.page
      console.log(this.page)
    }
  },
  methods: {
    onLoad() {
      var chat = storage.get('GET_CHAT')
      console.log('chat', chat)
      let guest_id = chat != undefined && chat.id != undefined ? chat.id : 0
      let page = this.page++
      // 异步更新数据
      setTimeout(() => {
        this.$store.dispatch('getSignList', { guest_id, page }).then(res => {
          console.log(res.data)

          this.list = this.list.concat(res.data)
          // 加载状态结束
          this.loading = false

          if (this.list.length === 0 && this.page === 1) {
            this.noData = true
          }

          // 如果加载完毕，显示没有更多了
          if (res.data.length === 0) {
            this.finished = true
          }
        })
      }, 1000)
    },
    // 返回上一页
    onClickLeft() {
      this.$router.go(-1)
    }
  }
}
</script>

<style>
</style>