<template>
  <div>
    <van-nav-bar title="跟进列表" left-arrow left-text="返回" @click-left="onClickLeft" />
    <van-list v-model="loading" :finished="finished" finished-text="没有更多了" @load="onLoad">
      <van-cell
        v-for="(item,index) in list"
        :key="index"
        is-link
        :url="'/complaint/view?id='+item.id"
      >
        <template #title>
          <van-tag v-show="item.is_urgent != '0'" type="danger">紧急</van-tag>
          【{{item.type_name}}】{{item.question}}
        </template>
        <template #label>
          <div>责任人：{{item.to_member_name}}</div>
          <div>
            时间：{{item.created_at | dateformat('YYYY-MM-DD')}} |
            <van-tag plain type="primary">{{item.total}}</van-tag>&nbsp; 条回复
          </div>
        </template>
      </van-cell>
    </van-list>

    <van-empty v-show="noData" description="暂无数据" />

    <div class="bottom">
      <div style="margin:10px;">
        <van-button round block type="info" url="/complaint/create">发起跟进</van-button>
      </div>
    </div>
  </div>
</template>

<script>
import storage from 'store'
export default {
  name: 'Complaint',
  data() {
    return {
      page: 1,
      list: [],
      loading: false,
      finished: false,
      noData: false, // 如果没有数据，显示暂无数据
      isLoading: false // 下拉的加载图案
    }
  },
  mounted() {},
  methods: {
    onLoad() {
      var chat = storage.get('GET_CHAT')
      let guest_id = chat != undefined && chat.id != undefined ? chat.id : 0
      let page = this.page++

      var that = this
      // 异步更新数据
      setTimeout(() => {
        that.$store.dispatch('getComplaint', { guest_id, page }).then(res => {
          console.log('item', res.data)

          that.list = that.list.concat(res.data)

          console.log('list', that.list)
          // 加载状态结束
          that.loading = false

          if (that.list.length === 0 && that.page === 1) {
            that.noData = true
          }

          // 如果加载完毕，显示没有更多了
          if (res.data.length === 0) {
            that.finished = true
          }
        })
      }, 1000)
    },
    // 返回上页
    onClickLeft() {
      history.go(-1)
    }
  }
}
</script>


<style scoped>
.bottom {
  position: fixed;
  bottom: 0;
  left: 0;
  width: 100%;
  background: #fff;
  border-top: 1px #f1f1f1 solid;
}
</style>
