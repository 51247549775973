<template>
  <div class="one-dropdownlist-container">
    <van-field
      readonly
      clickable
      name="picker"
      :value="item.value"
      :label="item.title"
      :placeholder="item.value || '点击选择'"
      @click="showPicker = true"
      is-link
    />

    <van-popup
      v-model="showPicker"
      closeable
      close-icon="close"
      position="top"
      :style="{ height: '100vh',paddingBottom:'100px',boxSizing:'border-box'}"
    >
      <div class="one-content-dropdownlist">
        <van-cell-group>
          <van-cell
            v-for="(item,index) in columns"
            :key="index"
            :title="item.name"
            @click="onConfirm(item)"
          />
        </van-cell-group>
      </div>
    </van-popup>
  </div>
</template>

<script>
import { fetchPost } from '@/utils/http.js'
export default {
  name: 'OneDropdownlist',
  props: ['item', 'guest_id'],
  data() {
    return {
      value: '',
      showPicker: false,
      columns: [],
    }
  },
  mounted() {
    this.initData()
  },
  methods: {
    initData() {
      var extra = this.item.extra
      this.columns = extra.map((it) => {
        return { name: it }
      })
      console.log(this.columns)
    },
    onCancel() {
      this.showPicker = false
    },
    onConfirm(value) {
      this.value = value.name
      this.item.value = value.name
      this.showPicker = false
      let form = {
        field: this.item.name,
        value: value.name,
        guest_id: this.guest_id,
        config_id: this.item.id,
      }
      console.log('form', form)
      fetchPost(`guest/save`, form).then((res) => {
        console.log('res', res)
        this.$notify({ type: 'success', message: res.message })
      })
    },
  },
}
</script>

<style lang="less" scoped>

</style>