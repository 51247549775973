<template>
  <div class="home">
    <van-nav-bar
      title="合同"
      left-arrow
      left-text="返回"
      @click-left="onClickLeft"
    />

    <van-divider color="#ff3300">选择分类</van-divider>

    <van-empty v-show="menus.length == 0" description="请补充分类" />

    <van-grid :column-num="3" :clickable="true">
      <van-grid-item
        v-for="(item, index) in menus"
        :key="index"
        :icon="item.icon || 'photo-o'"
        :text="item.label"
        :to="item.url"
        :dot="item.dot"
      >
        <div slot="text">{{ item.title }}</div>
      </van-grid-item>
    </van-grid>

    <!-- 载入 -->
    <van-overlay :show="!isReady">
      <div class="wrapper">
        <div class="block">
          <van-loading size="36px" type="spinner" color="#1989fa" vertical
            >加载中...</van-loading
          >
        </div>
      </div>
    </van-overlay>
  </div>
</template>

<script>
// @ is an alias to /src
import { fetchGet } from '@/utils/http.js'

export default {
  name: 'Home',
  components: {},
  provide() {
    return {
      reload: this.reload,
    }
  },
  data() {
    return {
      pid: 0,
      isReady: false,
      isRouterAlive: true,
      menus: [],
    }
  },
  watch: {
    $route(to, from) {
      // 对路由变化作出响应...
      console.log('to,', to)
      console.log('from', from)
      if (to.query.id != from.query.id) {
        this.pid = to.query.id
        this.initData()
      }
    },
  },
  mounted() {
    this.pid = this.$route.query.id
    this.initData()
  },
  created: function() {
    this.pid = this.$route.query.id
    console.log('pid', this.pid)
  },
  methods: {
    reload() {
      this.isRouterAlive = false
      this.$nextTick(() => (this.isRouterAlive = true))
    },

    // 获取本页需要的数据
    initData() {
      this.getPreCate()
      this.isReady = true
    },

    onClickLeft() {
      history.go(-1)
    },

    // 获取预约分类
    getPreCate() {
      fetchGet(`contract-cate/index`, { pid: this.pid }).then((res) => {
        let data = res.data
        let menus = []
        data.map(function(item) {
          let menu = {
            title: item.title,
            icon: item.icon,
            dot: item.children.length ? true : false,
            url: item.children.length
              ? '/contract/select?id=' + item.id
              : '/contract/create?cid=' + item.id + '&title=' + item.title,
          }
          menus.push(menu)
        })

        this.menus = menus
        console.log('res', this.menus)
      })
    },
  },
}
</script>

<style>
.one-icon {
  width: 1em;
  height: 1em;
  font-size: 1.5em;
  vertical-align: -0.15em;
  fill: currentColor;
  overflow: hidden;
}
.one-content {
  width: 100%;
  padding: 30px 0;
  border-radius: 10px;
}
.wrapper {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
}

.block {
  width: 120px;
  height: 120px;
  align-items: center;
  justify-content: center;
  display: flex;
  background-color: rgba(255, 255, 255, 0.8);
  border-radius: 5px;
}
</style>
