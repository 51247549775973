<template>
  <div id="app">
    <router-view />
  </div>
</template>

<script>
export default {
  methods: {
    reload() {
      location.reload()
    },
  },
}
</script>

<style lang="less">
#app {
  width: 100%;
  height: 100%;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background: #faecec;
  color: #2c3e50;
  position: relative;
}
</style>
