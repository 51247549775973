<template>
  <div>
    <van-field
      :clearable="clearable"
      :type="type"
      :label="label"
      :placeholder="placeholder"
      :rules="rules"
      :right-icon="rightIcon"
    />
  </div>
</template>

<script>
export default {
  name: 'OneFieldIndex',
  props: {
    clearable: {
      type: Boolean,
      default: false,
    },
    type: {
      type: String,
      default: 'text',
    },
    label: {
      type: String,
      default: '',
    },
    rightIcon: {
      type: String,
      default: '',
    },
    leftIcon: {
      type: String,
      default: '',
    },
    placeholder: {
      type: String,
      default: '',
    },
    rules: {
      type: Array,
      default() {
        return [{ required: true }]
      },
    },
  },
}
</script>
