<template>
  <div id="take-form">
    <van-nav-bar :title="pageTitle" left-arrow left-text="返回" @click-left="onClickLeft" />

    <van-swipe>
      <van-swipe-item v-for="(image, index) in covers" :key="index">
        <img v-lazy="image" :data-index="index" @click="onShowImage(index)" style="width:100%;" />
      </van-swipe-item>
    </van-swipe>
    <van-divider color="#ff3300">合同可以左右滑动，点击合同可放大拉伸显示。</van-divider>
    <div class="bottom">
      <div style="margin:10px;">
        <van-button round block type="info" @click="onSign">签名审核</van-button>
      </div>
    </div>
  </div>
</template>

<script>
import { fetchGet } from '@/utils/http.js'

import { ImagePreview } from 'vant'

export default {
  name: 'ContractForm',
  components: {},
  data() {
    return {
      active: 2,
      pageTitle: '审核合同',
      id: '',
      contract: {},
      covers: [
        'http://img.yikeyiqun.cn/images/2020/05/02/image_1588407330_hBazXM18.png',
        'http://img.yikeyiqun.cn/images/2020/05/02/image_1588407330_kFvv7lKO.png',
        'http://img.yikeyiqun.cn/images/2020/05/02/image_1588407331_FMkDmGuU.png',
        'http://img.yikeyiqun.cn/images/2020/05/02/image_1588407331_pwfwfAnO.png'
      ]
    }
  },

  mounted: function() {
    this.id = this.$route.query.id
    this.getCate()
  },
  methods: {
    onShowImage(index) {
      ImagePreview({
        images: this.covers,
        startPosition: index,
        onClose() {
          // do something
        }
      })
    },

    onSign() {
      this.$dialog
        .confirm({
          title: '合同审核',
          message: '该合同符合要求吗？'
        })
        .then(() => {
          this.$router.push({ name: 'ContractSign', query: { id: this.id } })
        })
        .catch(() => {
          // on cancel
          this.$toast.fail('已取消')
        })
    },
    // 返回上页
    onClickLeft() {
      history.go(-1)
    },
    init() {
      this.getCate()
    },
    // 获取商户详情
    getCate() {
      fetchGet(`contract/view`, { id: this.id }).then(res => {
        console.log('view', res.data)
        this.title = res.data.title
        this.contract = res.data.contract
        this.fields = res.data.fields
      })
    }
  }
}
</script>

<style scoped>
.bottom {
  position: fixed;
  bottom: 0;
  left: 0;
  width: 100%;
  background: #fff;
  border-top: 1px #f1f1f1 solid;
}
</style>
