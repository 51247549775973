import Vue from 'vue'
import Vuex from 'vuex'

import app from './modules/app'
import take from './modules/take'
import user from './modules/user'
import sign from './modules/sign'
import contract from './modules/contract'
import complaint from './modules/complaint'

import getters from './getters'

Vue.use(Vuex)

export default new Vuex.Store({
  modules: {
    app,
    user,
    take,
    sign,
    complaint,
    contract,
  },
  state: {},
  mutations: {},
  actions: {},
  getters,
})
