import { fetchPost, fetchGet } from '@/utils/http.js'
import {
  CONTRACT_CATE_PID,
  CONTRACT_CATE_ID,
  CONTRACT_CATE,
  CONTRACT_CATE_INFO,
  CONTRACT_ID,
  CONTRACT_INFO,
  CONTRACT_CREATE,
  GALLERY,
} from '../mutation-types'

const state = {
  // 商户信息
  merchantId: 0,
  merchant: {},

  // 群信息
  chatId: '',
  chat: {},

  // 预约
  CONTRACT_cate_id: 0,
  CONTRACT_cate_pid: 0,
  CONTRACT_cate: {},
  CONTRACT_cate_info: {},
  CONTRACT_id: 0,
  CONTRACT_info: {},
  gallery: {},
}

const mutations = {
  [CONTRACT_CATE_PID]: (state, id) => {
    state.contract_cate_pid = id
  },
  [CONTRACT_CATE_ID]: (state, id) => {
    state.contract_cate_id = id
  },
  [CONTRACT_CATE]: (state, res) => {
    state.contract_cate = res
  },
  [CONTRACT_CATE_INFO]: (state, res) => {
    state.contract_cate_info = res
  },
  [CONTRACT_ID]: (state, id) => {
    state.contract_id = id
  },
  [CONTRACT_INFO]: (state, res) => {
    state.contract_info = res
  },
  [CONTRACT_CREATE]: (state, res) => {
    state.contract_info = res
  },
  [GALLERY]: (state, res) => {
    state.gallery = res
  },
}

const actions = {
  getGallery({ commit }, params) {
    return new Promise((resolve, reject) => {
      fetchGet(`gallery/index`, params)
        .then((res) => {
          commit(GALLERY, res.data)
          resolve(res.data)
        })
        .catch((e) => {
          reject(e)
        })
    })
  },
  getContractCatePid({ commit }, id) {
    return commit(CONTRACT_CATE_PID, id)
  },
  getContractCateId({ commit }, id) {
    return commit(CONTRACT_CATE_ID, id)
  },
  getContractCate({ commit }) {
    return new Promise((resolve, reject) => {
      fetchGet(`contract-cate/index`, { pid: state.contract_cate_pid })
        .then((res) => {
          commit(CONTRACT_CATE, res.data)
          resolve()
        })
        .catch((e) => {
          reject(e)
        })
    })
  },
  getContractCateInfo({ commit }) {
    return new Promise((resolve, reject) => {
      fetchGet(`contract-cate/view`, { id: state.contract_cate_id })
        .then((res) => {
          commit(CONTRACT_CATE_INFO, res.data)
          resolve()
        })
        .catch((e) => {
          reject(e)
        })
    })
  },
  createContract({ commit }, data) {
    return new Promise((resolve, reject) => {
      fetchPost(`contract/create`, data)
        .then((res) => {
          commit(CONTRACT_INFO, res.data)
          resolve(res)
        })
        .catch((e) => {
          reject(e)
        })
    })
  },
}

export default {
  namespace: true,
  state,
  mutations,
  actions,
}
