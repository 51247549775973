<template>
  <div class="home">
    <Skeleton v-show="ShowLoading" />
	<!-- <div>{{error}}</div> -->
    <div v-if="!isShowLoading">
      <van-nav-bar title="一客一群" />
      <van-grid :column-num="3" :clickable="true">
        <van-grid-item
          v-for="(item, index) in menus"
          :key="index"
          icon="photo-o"
          :text="item.label"
          :to="item.url"
        >
          <div class="one-content" :style="'background:' + item.bgcolor">
            <div slot="icon">
              <svg class="one-icon" aria-hidden="true">
                <use :xlink:href="item.icon" />
              </svg>
            </div>
            <div slot="text">{{ item.label }}</div>
          </div>
        </van-grid-item>
      </van-grid>
    </div>
	<!-- <div style="display:none; " @click="faxiaoxi()">
		点击发送消息 
	</div> -->
  </div>
</template>

<script>
import storage from "store";
import { GET_MERCHANT, USER_INFO } from "@/store/mutation-types";
import Skeleton from "@/views/home/skeleton";
import { funMenus } from "@/utils/params";
import wxJs from "@/utils/wx-sdk";
import { getQueryString } from "@/utils/common";
import { fetchGet } from "@/utils/http.js";
import store from "../store/";
import { jsUrl } from "@/utils/params";
// import { GET_MERCHANT_ID } from '../store/mutation-types'


export default {
  name: "Home",
  components: {
    Skeleton,
  },
  data() {
    return {
      isShowLoading: true, // 是否加载动画
      ShowLoading: false, // 是否加载动画
      chatInfo: {}, // 群资料
      isReady: false,
      code: null,
      func: [],
      menus: funMenus,
		error:"",
		is_check:false
    };
  },
  mounted() {
		let merchant_id = getQueryString("merchant_id");
		let is_check = getQueryString("is_check");
		if(is_check){
			this.is_check = true;
		}
    if (!merchant_id) {
      this.getqunid();
    } else {
			this.ShowLoading=true;
      this.getMerchant()
      if (process.env.NODE_ENV == 'development') {
        //群ID
        this.$store.dispatch('setChatId', 'wrpJT-BwAA1puSuS-mKmMXYg5hY67X0Q')
        this.$store.dispatch('getChat')
      } else {
        this.getWorkCheck()
      }
    }
  },
  methods: {
		faxiaoxi(){
			let that = this;
			wxJs
				.agentInit(["openExistedChatWithMsg"])
				.then(() => {
					// 返回当前客户群的群聊ID
					window.wx.invoke("openExistedChatWithMsg", {
						chatId: "wrLIDdCwAAD0lC6pZpixaFQq-p28fxSA",
						msg: {
							msgtype: "link",
							link: {
								title: "干点活专属消息",
								desc: "身边的技术合伙人",
								url: "http://www.gandianhuo.com/",
								imgUrl: "http://www.gandianhuo.com/tpl/default/asset/img/gdhlogo.png"
							}
						}
					},function(res){
						console.log(res)
						if (res.err_msg == "openExistedChatWithMsg:ok")
						{
						alert("发送成功");
						}else {
						alert(res.err_msg);
						}
					}
							
					);
				})
				.catch(() => {
					that.$notify({
						type: "primary",
						message: "请重新进入",
						onClose: () => {
							window.wx.closeWindow();
						},
					});
			});
			
		},
		getqunid(){
			let that = this;
			wxJs
				.agentInit(["getCurExternalChat", "sendChatMessage"])
				.then(() => {
					// 返回当前客户群的群聊ID
					window.wx.invoke("getCurExternalChat", {}, function (res) {
						if (res.err_msg == "getCurExternalChat:ok") {
							let chatId = res.chatId;
							that.getChatId(chatId);
						} else {
							that.$notify({
								message: "群ID获取失败",
								type: "danger",
							});
						}
					});
				})
				.catch(() => {
					that.$notify({
						type: "primary",
						message: "请重新进入",
						onClose: () => {
							window.wx.closeWindow();
						},
					});
					// that.$store.dispatch("clearCache").then(() => {
					// 	that.$notify({
					// 		type: "primary",
					// 		message: "请重新进入",
					// 		onClose: () => {
					// 			window.wx.closeWindow();
					// 		},
					// 	});
					// });
			});
		},
    getconfig() {
      let work_user = sessionStorage.getItem("work_user");
      if (work_user) {
        store.dispatch("getconfig").then((res) => {
          console.log("merchant", res);
          let appId = res.workwechat_corp_id;
          let redirectUrl = encodeURIComponent(jsUrl + "auth");
          console.log("merchant:", appId, redirectUrl);
          console.log(process.env);
          // if (process.env.NODE_ENV == "development") {
          //   console.log("development");
          // } else {
          //    window.location.href = `https://open.weixin.qq.com/connect/oauth2/authorize?appid=${appId}&redirect_uri=${redirectUrl}&response_type=code&scope=snsapi_userinfo&state=STATE#wechat_redirect`;
          // }
        });
      }
    },
    async getChatId(chatId) {
			var data;
			if(this.is_check){
				data = { chatId: chatId ,is_check:true}
			}else{
				data = { chatId: chatId}
			}
      await fetchGet("base/getchat", data)
				.then((resp) => {
					if (resp.data.code == 200) {
						this.$store.dispatch('setMerchantId',  resp.data.merchant_id)
						window.location.href = "/?merchant_id=" + resp.data.merchant_id;
					}else if (resp.data.code == 110) {
						this.$dialog
							.confirm({
								title: '提示',
								message: resp.data.msg,
							})
							.then(() => {
								// on confirm
								this.$store.dispatch('setMerchantId',  resp.data.merchant_id)
								window.location.href = "/?is_check=true";
							})
							.catch(() => {
								// on cancel
								this.$dialog
									.alert({
										title: '提示',
										message: "您拒绝了群创建",
									})
									.then(() => {
										window.wx.closeWindow()
									})
							})
					}else{
						this.$notify({
							type: "danger",
							message: resp.data.msg,
							duration: 9999999
						});
					}
        })
        .catch(() => {
			// this.error = JSON.stringify(err.data)
					this.$notify({
						type: "danger",
						message: "群主离职请联系管理员",
						duration: 9999999
					});
        });
    },
    // 判断商户是否存在
    getMerchant() {
      let merchant = storage.get(GET_MERCHANT);
      let member = storage.get(USER_INFO);
      console.log("merchant", merchant);
      var funcs = merchant != undefined ? merchant["func"] : [];

      console.log("已开通功能：", funcs);
      console.log("已开通功能：", merchant);
      console.log("会员权限:", member);

      var menu = this.menus;
      var newMenu = [];
      console.log('newMenu')
      console.log(newMenu)
      menu.forEach((item) => {
        funcs.forEach((li) => {
          let url = item.url;
          if (url.search(li) != -1) {
            newMenu.push(item);
          }
        });
      });

      // 部门判断
      this.menus = newMenu;

      // 远程获取权限
      var that = this;
      this.$store.dispatch("getMenu").then((res) => {
        console.log("用户首页权限：", res);
        var menu = that.menus;
        console.log("menu")
        console.log(menu)
        var newMenu = [];
        menu.forEach((item) => {
          console.log('item')
          console.log(item)
          res.forEach((li) => {
            let url = item.url;
            if (url.search(li) != -1) {

              newMenu.push(item);
            }
          });
        });
        that.menus = newMenu;
      });

      this.$notify({
        message: "已获取商户信息",
        type: "primary",
      });

      this.isShowLoading = false;
      this.ShowLoading = false;
    },

    // 获取企业认证
    getWorkCheck() {
      this.$toast.loading({
        message: "群ID获取中..",
        forbidClick: true,
        duration: process.env.NODE_ENV != "development" ? 0 : 1000 * 1,
      });

      wxJs.init(["checkJsApi", "agentConfig"]).then(() => {
        window.wx.checkJsApi({
          jsApiList: [
            "agentConfig",
            "sendChatMessage",
            "selectExternalContact",
            "getCurExternalChat",
          ],
          success: () => {
            this.$notify({
              message: "已完成微信授权",
              type: "primary",
            });
            this.agentInit();
          },
          error: (err) => {
            this.$notify({
              type: "danger",
              message: "微信认证授权失败,请重试",
            });
            console.log("err", err);
          },
        });
      });
    },
		invoke(chatId){
			fetchGet("base/invitaion", { chatId: chatId })
				.then((resp) => {
					if (resp.data.code == 200 && resp.data.data.length>0) {
						var userIdsToAdd = resp.data.data.join(";");
						window.wx.invoke("updateEnterpriseChat", {
							chatId: chatId, //通过企业微信创建群聊接口返回的chatId
							userIdsToAdd: userIdsToAdd,    //参与会话的企业成员列表，格式为userid1;userid2;...，用分号隔开。
							}, function(res) {
									if (res.err_msg == "updateEnterpriseChat:ok") {
										this.$notify({
											message: "邀请成功",
											type: "primary",
										});
									}
							}
						);
					}
				})
				.catch((err) => {
					this.$notify({
						type: "danger",
						message: err.data.message,
						duration: 9999999
					});
				});
		},
        tankuang(chatId){
            fetchGet("contract/tankuang", { guest_id: chatId })
                .then((res) => {
                    if(res.data.send == 1){
                        wxJs
                          .agentInit(["openExistedChatWithMsg"])
                          .then(() => {
                              // 返回当前客户群的群聊ID
                              window.wx.invoke("openExistedChatWithMsg", {
                                  chatId: res.data.chat_id,
                                  msg: {
                                      msgtype: "link",
                                      link: {
                                          title: res.data.message.title,
                                          desc: res.data.message.desc,
                                          url: res.data.message.link,
                                          imgUrl: res.data.message.imgUrl
                                      }
                                  }
                              },function(res){
                                  if (res.err_msg == "openExistedChatWithMsg:ok"){
                                      this.$toast({
                                        message: '发送成功',
                                        type: 'success'
                                      })
                                  }else{
                                      this.$toast('发送失败')
                                  }
                              });
                          })
                    }
                    
                })
        },
    // 获取应用参数
    async agentInit() {
      let that = this;
      wxJs
        .agentInit(["getCurExternalChat", "sendChatMessage", "updateEnterpriseChat"])
        .then(() => {
          // 返回当前客户群的群聊ID
          window.wx.invoke("getCurExternalChat", {}, function (res) {
            if (res.err_msg == "getCurExternalChat:ok") {
              let chatId = res.chatId;
              that.$store.dispatch("setChatId", chatId);
              that.$notify({
                message: "已获取群ID成功",
                type: "success",
              });
							that.invoke(chatId);
							that.tankuang(chatId);
              that.$store.dispatch('getChat')

              that.$store.dispatch('syncChant').then(() => {
                that.$notify({ type: 'success', message: '群信息同步完成' })
              })

              that.$toast.clear();
            } else {
              that.$notify({
                message: "群ID获取失败",
                type: "danger",
              });
              //错误处理
              console.log("wx.invoke", res.err_msg);
            }
          });
        })
        .catch(() => {
          that.$store.dispatch("clearCache").then(() => {
            that.$notify({
              type: "primary",
              message: "请重新进入",
              onClose: () => {
                window.wx.closeWindow();
              },
            });
          });
        });
    },

    // 选择动作
    checkUrl(url) {
      switch (url) {
        case "pre":
          break;
        case "take":
          this.takeShowPicker = true;
          break;
        case "sign":
          this.signShowPicker = true;
          break;
        default:
          this.$toast.fail("请准备分类");
      }
    },
  },
};
</script>

<style>
.one-icon {
  width: 1em;
  height: 1em;
  font-size: 1.5em;
  vertical-align: -0.15em;
  fill: currentColor;
  overflow: hidden;
}
.one-content {
  width: 100%;

  text-align: center;
  padding: 30px 0;
  border-radius: 10px;
}
.wrapper {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
}

.block {
  width: 120px;
  height: 120px;
  align-items: center;
  justify-content: center;
  display: flex;
  background-color: rgba(255, 255, 255, 0.8);
  border-radius: 5px;
}
</style>
