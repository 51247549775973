<template>
  <div class="take-index">
    <van-nav-bar title="拍照列表" left-arrow left-text="返回" @click-left="onClickLeft" />

    <van-empty description="暂无数据" />
  </div>
</template>

<script>
export default {
  name: 'takeIndex',
  data() {
    return {}
  },
  methods: {
    // 返回上一页
    onClickLeft() {
      this.$router.go(-1)
    }
  }
}
</script>

<style>
</style>