import { fetchPost, fetchGet } from '@/utils/http.js'

import { SIGN_ID, SIGN, SIGN_INFO, SIGN_CREATE } from '../mutation-types'

const state = {
  // 签字
  sign_id: 0,
  SIGN: [],
  sign_info: {},
}

const mutations = {
  [SIGN_ID]: (state, id) => {
    state.sign_id = id
  },
  [SIGN]: (state, res) => {
    state.sign = res
  },
  [SIGN_INFO]: (state, res) => {
    state.sign_info = res
  },
  [SIGN_CREATE]: (state, res) => {
    state.sign_info = res
  },
}

const actions = {
  getSignList({ commit }, params) {
    return new Promise((resolve, reject) => {
      fetchGet(`sign/index`, params)
        .then((res) => {
          commit(SIGN, res)
          resolve(res)
        })
        .catch((e) => {
          reject(e)
        })
    })
  },
  createSign({ commit }, data) {
    return new Promise((resolve, reject) => {
      fetchPost(`sign/create`, data)
        .then((res) => {
          commit(SIGN_INFO, res.data)
          resolve(res)
        })
        .catch((e) => {
          reject(e)
        })
    })
  },
}

export default {
  namespace: true,
  state,
  mutations,
  actions,
}
