<template>
  <div id="take-form">
    <van-nav-bar title="发起跟进" left-arrow left-text="返回" @click-left="onClickLeft" />

    <van-form validate-first @failed="onFailed">
      <van-field
        v-model="message"
        rows="5"
        autosize
        type="textarea"
        maxlength="5000"
        placeholder="请输入跟进内容"
        show-word-limit
      />
      <van-field
        v-model="type"
        label="类型"
        readonly
        clickable
        is-link
        @click="showType = true"
        placeholder="请输入跟进类型"
      />
      <van-cell center title="是否紧急">
        <template #right-icon>
          <van-switch v-model="is_urgent" size="24" />
        </template>
      </van-cell>

      <van-field
        readonly
        clickable
        name="picker"
        :value="value"
        label="指定责任人"
        placeholder="点击选择群中同事"
        @click="showShare = true"
      />

      <van-field name="uploader">
        <template #input>
          <one-uploader :data="covers" :total="9" @syncdata="chooseImage" />
        </template>
      </van-field>

      <div style="margin: 30px;">
        <van-button round block type="info" @click="submit()" native-type="submit">提交</van-button>
      </div>
    </van-form>

    <van-popup
      v-model="showShare"
      closeable
      position="right"
      :style="{ height: '100%',width:'80%' }"
    >
      <van-nav-bar title="选择责任人" />
      <van-cell-group>
        <van-cell
          v-for="(item,val) in options"
          :key="val"
          :title="item.name"
          @click.native="onSelect(item)"
        />
      </van-cell-group>
    </van-popup>
    <van-action-sheet
      v-model="showType"
      :actions="typeOptions"
      cancel-text="取消"
      close-on-click-action
      @select="selectType"
      @cancel="showType = false"
    />
  </div>
</template>

<script>
import storage from 'store'
import { fetchGet, fetchPost } from '@/utils/http.js'
import OneUploader from '@/components/one-uploader/index.vue'
import wxJs from '@/utils/wx-sdk'

export default {
  name: 'TakeCreate',
  data() {
    return {
      showShare: false,
      showType: false,
      typeOptions: [],
      options: [{ name: '微信', icon: 'wechat' }],
      isChat: false,
      chatInfo: {},
      message: '',
      is_urgent: 0,
      covers: [],
      value: '',
      type: '',
      columns: [],
      loading: true,
      showPicker: false,
    }
  },
  components: {
    OneUploader,
  },
  mounted: function () {
    this.getType()
    this.getWorkCheck()
    this.getStaff()
  },
  methods: {
    onSelect(option) {
      this.showShare = false
      console.log('option', option)
      this.value = option.userid
    },
    selectType(aciton, index) {
      console.log(aciton, index)
      this.type = aciton.name
      this.showType = false
    },
    getType() {
      console.log('typea')
      this.$store.dispatch('getComplaintTypeList').then((res) => {
        console.log('type', res.data)
        this.typeOptions = res.data
      })
    },
    getStaff() {
      var chat = storage.get('GET_CHAT')
      console.log('chat', chat)
      fetchGet(`guest/staff`, { id: chat.id }).then((item) => {
        this.options = item.data
        console.log('staff', item)
        this.loading = false
      })
    },
    chooseImage(res) {
      console.log('chooseImage', res)
      this.covers.push(res)
    },
    onConfirm(value) {
      this.value = value
      this.showPicker = false
    },
    // 获取企业认证
    getWorkCheck() {
      wxJs.init(['checkJsApi', 'agentConfig', 'chooseImage']).then((wx) => {
        console.log('WxJs.init', wx)

        window.wx.checkJsApi({
          jsApiList: [
            'agentConfig',
            'sendChatMessage',
            'selectExternalContact',
            'getCurExternalChat',
          ],
          success: (res) => {
            this.$notify({
              message: '已获取微信config认证',
              type: 'primary',
            })
            console.log('wx.checkJsApi', res)
            this.agentInit()
            wx.getLocation({
              type: 'wgs84',
              success: function (lres) {
                this.location = lres
                console.log('定位:', lres)
              },
            })
          },
          error: (err) => {
            console.log('err', err)
          },
        })
      })
    },

    // 获取应用参数
    async agentInit() {
      wxJs
        .agentInit(['getCurExternalChat', 'sendChatMessage', 'chooseImage'])
        .then((cc) => {
          console.log('agentList', cc)
        })
    },

    submit() {
      var chat = storage.get('GET_CHAT')
      console.log('chat', chat)
      fetchPost(`complaint/create`, {
        covers: this.covers,
        is_urgent: this.is_urgent ? 1 : 0,
        guest_id: chat.id,
        to_member_id: this.value,
        question: this.message,
        type_name: this.type,
      }).then((res) => {
        console.log('res', res)
        this.$dialog
          .alert({
            title: '完成',
            message: res.message,
          })
          .then(() => {
            return this.$router.push({ path: '/' })
          })
      })
    },
    // 异步校验函数返回 Promise
    asyncValidator(val) {
      return new Promise((resolve) => {
        this.$toast.loading('验证中...')

        setTimeout(() => {
          this.$toast.clear()
          resolve(/\d{6}/.test(val))
        }, 1000)
      })
    },

    // 出错提示
    onFailed(errorInfo) {
      console.log('failed', errorInfo)
    },

    // 返回上页
    onClickLeft() {
      history.go(-1)
    },
    // 获取商户详情
    getCate() {
      fetchGet(`take-cate/view`, { id: this.cid }).then((res) => {
        console.log('view', res.data)
        this.title = res.data.title
        this.pageTitle = res.data.title + '拍照'
      })
    },
  },
}
</script>

<style scoped>
</style>