import { fetchPost } from './http'
import storage from 'store'
import { GET_MERCHANT } from '../store/mutation-types'

//微信公众号config接口
export function wxConfig(data) {
  return fetchPost('wechat/get_jssdk_config', data)
}

//微信公众号获取code
export function wxCode(url) {
  if (url.indexOf('?code') > 0) {
    return url
  }
  return false
}
//微信获取用户信息
export function wxLogin(data) {
  return fetchPost('wechat/login', data)
}

// 获取参数
export function getQueryString(name) {
  var reg = new RegExp('(^|&)' + name + '=([^&]*)(&|$)')
  var r = window.location.search.substr(1).match(reg)
  if (r != null) return unescape(r[2])
  return null
}

// 获取商户参数
export function getMerchantConfig(merchant_id, callbak) {
  fetchPost(`merchant/config`, { merchant_id }).then((res) => {
    console.log('getMerchantConfig', res)
    if (res.code == 200) {
      storage.set(GET_MERCHANT, res.data)
      //localStorage.setItem('merchant', JSON.stringify(res.data))
      callbak(res.data)
    }
  })
}

// 判断用户是否具体该部门权限
export function checkDepartment(fromDepartment, userDepartment) {
  var userDep = userDepartment.split(',')
  var allDep = fromDepartment.split(',')

  userDep.forEach((li) => {
    if (allDep.indexOf(li) != -1) {
      return true
    }
  })
  return false
}

// 判断是否为指定会员权限
export function checkUserid(fromUserid, userid) {
  var allUserid = fromUserid.split(',')
  if (allUserid.indexOf(userid) != '-1') {
    return true
  }
  return false
}
