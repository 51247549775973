<template>
	<div>
		<van-nav-bar :title="pageTitle" left-arrow left-text="返回" @click-left="onClickLeft" />
		<div class="form-cell" v-for="(item,index) in detail.tree.all_data" :key="index">
			<!-- 文本输入框 -->
			<van-field
				v-if="item.module == 'input'"
				:name="item.name"
				:label="item.title"
				:placeholder="item.placeholder=='null'?'':item.placeholder"
				:type="item.form_type=='digit'?'number':item.form_type=='number'?'digit':'text'"
				:value="form[item.name]"
				v-model="form[item.name]"
			/>
			<!-- 多文本 -->
			<van-field
				v-if="item.module == 'textarea'"
				:type="item.module"
				autosize
				show-word-limit
				:name="item.name"
				:label="item.title"
				:placeholder="item.placeholder=='null'?'':item.placeholder"
				:value="form[item.name]"
				v-model="form[item.name]"
			/>
			<!-- 多选 -->
			<div v-if="item.module == 'checkbox'">
				<div><van-cell :title="item.title" /></div>
				<van-checkbox-group v-model="form[item.name]">
					<van-checkbox :name="item2.value" v-for="(item2,index2) in item.list" :key="index2">{{item2.value}}</van-checkbox>
				</van-checkbox-group>
			</div>
			<!-- 单选 -->
			<div v-if="item.module == 'radio'">
				<div><van-cell :title="item.title" /></div>
				<van-radio-group v-model="form[item.name]">
					<van-radio :name="item2.value" v-for="(item2,index2) in item.list" :key="index2">{{item2.value}}</van-radio>
				</van-radio-group>
			</div>
			
			<!-- 下拉框 -->
			<div v-if="item.module == 'picker'">
				<van-field
					readonly
					clickable
					:label="item.title"
					:value="form[item.name]"
					v-model="form[item.name]"
					placeholder="请选择"
					@click="item.showtype = true"
				/>
				<van-popup v-model="item.showtype" round position="bottom">
					<van-picker
						:title="item.title"
						show-toolbar
						:columns="item.listArr"
						:ref="item.name"
						@cancel="item.showtype = false"
						@confirm="pickerSave(item.name,index)"
					/>
				</van-popup>
			</div>
			<!-- 日期 -->
			<div v-if="item.module == 'time_one'">
				<van-cell title="选择日期" :value="form[item.name]" @click="showtype(index)" />
				<van-calendar :min-date="minDate" v-model="item.showtype" @confirm="time_oneSave" />
			</div>
			<!-- 日期范围 -->
			<div v-if="item.module == 'time_two'">
				<van-cell title="选择日期" :value="form[item.name]" @click="showtype(index)" />
				<van-calendar allow-same-day :min-date="minDate" v-model="item.showtype" type="range" @confirm="time_twoSave" />
			</div>
			<!-- 地区 -->
			<div v-if="item.module == 'region'">
				<van-field
					readonly
					clickable
					:label="item.title"
					:value="form[item.name]"
					placeholder="请选择地区"
					@click="showtype(index)"
				/>
				<van-popup v-model="item.showtype" round position="bottom">
					<van-area
						:title="item.title"
						:area-list="areaList"
						:columns-placeholder="['请选择', '请选择', '请选择']"
						@cancel="item.showtype = false"
						@confirm="regionSave"
					/>
				</van-popup>
			</div>
			<!-- 多图上传 -->
			<div class="one-uploader-container" v-if="item.module == 'pic_arr'">
				<div style="font-size: 14px;">{{item.title}}</div>
				<div class="van-uploader">
					<div class="van-uploader__wrapper">
						<div class="van-uploader__preview" v-for="(item2,index2) in form[item.name]" :key="index2">
							<div class="van-image van-uploader__preview-image">
								<van-image :src="item2" class="van-image__img" style="object-fit: cover;">
									<template v-slot:error>加载失败</template>
								</van-image>
							</div>
							<i class="van-icon van-icon-clear van-uploader__preview-delete" @click="onDelete(index2,item.name)"></i>
						</div>
						<div class="van-uploader__upload" @click="chooseImage(item.name)">
							<i class="van-icon van-icon-photograph van-uploader__upload-icon">
							</i>
						</div>
					</div>
				</div>
			</div>
			<!-- 单图上传 -->
			<div class="one-uploader-container" v-if="item.module == 'pic'">
				<div style="font-size: 14px;">{{item.title}}</div>
				<div class="van-uploader">
					<div class="van-uploader__wrapper">
						<div class="van-uploader__preview" v-if="form[item.name]">
							<div class="van-image van-uploader__preview-image">
								<van-image :src="form[item.name]" class="van-image__img" style="object-fit: cover;">
									<template v-slot:error>加载失败</template>
								</van-image>
							</div>
							<i class="van-icon van-icon-clear van-uploader__preview-delete" @click="chooseImage2(item.name)"></i>
						</div>
						<div class="van-uploader__upload" @click="chooseImage2(item.name)" v-else>
							<i class="van-icon van-icon-photograph van-uploader__upload-icon">
							</i>
						</div>
					</div>
				</div>
			</div>
			<van-button v-if="item.module == 'button'" block :color="item.text_color" @click="submit">{{item.title}}</van-button>
		</div>
		<!-- <div>{{errjson}}</div> -->
	</div>
</template>

<script>
import { fetchGet, fetchPost } from '@/utils/http.js'
import area from './area.js'
import wxJs from '@/utils/wx-sdk'
import storage from 'store'
export default {
  name: 'Document',
  components: {
  },
  data() {
    return {
			id:"",
			cid:0,
			merchant:0,
			areaList:area,
			minDate:new Date(1990,0,1),
			detail:{},
			form:{},
			selectname:"",
			selectIndex:"",
			pageTitle: '单据上传',
			html:"",
			sub:"",
			errjson:"",
			data:"",
			formhtml:"",
			editjson:{}
    }
  },

  mounted: function () {
		this.cid = this.$route.query.cid;
		this.id = this.$route.query.id;
		this.merchant = storage.get('GET_CHAT');
		this.getWorkCheck();
		fetchGet(`fromdata/view`, { id: this.cid,guest_id: this.merchant.id}).then((e) => {
			var all_data = e.data.tree.all_data;
			var form={};
			for(var i=0;i<all_data.length;i++){
				// 选项框选项
				if(all_data[i].module == "picker"){
					all_data[i].listArr = [];
					for(var n=0;n<all_data[i].list.length;n++){
						all_data[i].listArr.push(all_data[i].list[n].range);
					}
				}
				// 选项框是否显示
				if(all_data[i].module == "picker" || all_data[i].module == "time_one" || all_data[i].module == "time_two" || all_data[i].module == "region"){
					all_data[i].showtype = false; 
				}
				// 数据
				if(all_data[i].module == "checkbox" || all_data[i].module == "pic_arr"){
					form[all_data[i].name] = [];
				}else if(all_data[i].module != "button"){
					form[all_data[i].name] = all_data[i].default || "";
				}
				if(all_data[i].module == "time_one"){
					form[all_data[i].name] = all_data[i].default;
				}
				if(all_data[i].module == "time_two"){
					form[all_data[i].name] = all_data[i].default1+" - "+all_data[i].default2;
				}
			}
			e.data.tree.all_data = all_data;
			this.detail = e.data;
			this.form = form;
			// this.formhtml = JSON.stringify(form);
			// this.errjson = JSON.stringify(e.data);
			// this.data = this.cid;
			
			if(this.id){
				fetchGet(`fromdata/editdata`, { id: this.id}).then((e) => {
					for(var o in e.data){
						form[o] = e.data[o]
					}
					this.form = form;
				})
			}
		})
  },
  methods: {
		// 提交
		submit() {
			var form = this.form;
			var list = this.detail.tree.all_data;
			for(var i=0;i<list.length;i++){
				if(list[i].empty && (form[list[i].name] == "" || form[list[i].name].length == 0)){
					this.$notify({
						type: "danger",
						message: "请填写"+list[i].title,
					});
					return false;
				}
				if(list[i].type == "form_text" && (list[i].empty||form[list[i].name].length>0)){
					if(list[i].form_type == "number" && isNaN(form[list[i].name])){
						this.$notify({
							type: "danger",
							message: list[i].title+"请填写为数字",
						});
						return false;
					}
					if(list[i].form_type == "digit" && isNaN(form[list[i].name])){
						this.$notify({
							type: "danger",
							message: list[i].title+"请填写为数字或带小数点的数字",
						});
						return false;
					}
					var reg = /(^\d{15}$)|(^\d{18}$)|(^\d{17}(\d|X|x)$)/;
					if(list[i].form_type == "idcard" && reg.test(form[list[i].name]) === false){
						this.$notify({
							type: "danger",
							message: list[i].title+"请填写为正确的身份证号",
						});
						return false;
					}
				}
			}
			if(this.id){
				fetchPost(`fromdata/editdata?id=`+this.id, {
					savedata:form
				}).then(res => {
					this.$dialog
						.alert({
							title: '完成',
							message: res.message
						})
						.then(() => {
							history.go(-1)
							// return this.$router.push({ path: '/' })
						})
				})
				// .catch((err) => {
				// 	this.errjson = JSON.stringify(err)
				// });
			}else{
				fetchPost(`fromdata/adddata`, {
					fid:this.cid,
					guest_id:this.merchant.id,
					savedata:form
				}).then(res => {
					this.$dialog
						.alert({
							title: '完成',
							message: res.message
						})
						.then(() => {
							return this.$router.push({ path: '/' })
						})
				})
				// .catch((err) => {
				// 	this.errjson = JSON.stringify(err)
				// });
			}
		},
		// 点击选择照片上传
		chooseImage: function(name) {
			var _this = this
			window.wx.chooseImage({
				sizeType: ['original', 'compressed'],
				sourceType: ['album', 'camera'],
				success: function(res) {
					console.log('选中的图片', res)
					_this.syncUpload(res.localIds,name,'arr')
				}
			})
		},
		chooseImage2: function(name) {
			var _this = this
			window.wx.chooseImage({
				count: 1,
				sizeType: ['original', 'compressed'],
				sourceType: ['album', 'camera'],
				success: function(res) {
					console.log('选中的图片', res)
					_this.syncUpload(res.localIds,name)
				}
			})
		},
		onDelete(index,name) {
			this.form[name].splice(index, 1)
		},
		syncUpload: function(localIds,name,type) {
			var _this = this
			if (localIds.length > 0) {
				var localId = localIds.pop()
				window.wx.uploadImage({
					localId: localId.toString(),
					isShowProgressTips: 1,
					success: function(res) {
						console.log('开始上传的数据', res)
		
						// TODO 图片上传
						fetchPost(`media/create`, { media_id: res.serverId }).then(mres => {
							console.log('mres', mres)
							window.setTimeout(function() {
								_this.getShow(mres.data.id,name,type)
							}, 2000)
						})
						window.setTimeout(function() {
							_this.syncUpload(localIds,name,type)
						}, 2000)
					}
				})
			}
		},
		// 获取图片
		async getShow(id,name,type) {
			var _this = this
			await fetchGet(`media/view`, { id }).then(res => {
				console.log('media/view', res.data.image)
				if(type == "arr"){
					_this.form[name].push(res.data.image)
				}else{
					_this.form[name] = res.data.image;
				}
			})
		},
		
		// chooseImage(res) {
		// 	console.log('chooseImage', res)
		// 	this.pic_arr.push(res)
		// },
		// onDelete(index) {
		// 	this.pic_arr.splice(index, 1)
		// },
		// pidAdd(res) {
		// 	console.log('chooseImage', res)
		// 	this.pic = res
		// },
		// picDelete() {
		// 	this.pic = ""
		// },
		getWorkCheck() {
			wxJs.init(['checkJsApi', 'agentConfig', 'chooseImage']).then(wx => {
				console.log('WxJs.init', wx)
		
				window.wx.checkJsApi({
					jsApiList: [
						'agentConfig',
						'sendChatMessage',
						'selectExternalContact',
						'getCurExternalChat'
					],
					success: res => {
						this.$notify({
							message: '已获取微信config认证',
							type: 'primary'
						})
						console.log('wx.checkJsApi', res)
						this.agentInit()
						wx.getLocation({
							type: 'wgs84',
							success: function(lres) {
								this.location = lres
								console.log('定位:', lres)
							}
						})
					},
					error: err => {
						console.log('err', err)
					}
				})
			})
		},
		// 获取应用参数
		async agentInit() {
			let that = this
			wxJs
				.agentInit(['getCurExternalChat', 'sendChatMessage', 'chooseImage'])
				.then(cc => {
					console.log('agentList', cc)
		
					window.wx.invoke('getCurExternalChat', {}, function(res) {
						if (res.err_msg == 'getCurExternalChat:ok') {
							let chatId = res.chatId //返回当前客户群的群聊ID
							localStorage.setItem('chatId', chatId)
							that.$notify({
								message: '已获取群ID成功',
								type: 'primary'
							})
							that.$toast.clear()
						} else {
							that.$notify({
								message: '群ID获取失败',
								type: 'danger'
							})
							//错误处理
							console.log('wx.invoke', res.err_msg)
						}
					})
				})
		},
		onClickLeft() {
			history.go(-1)
		},
		// 单选
		pickerSave(value,index) {
			this.form[value] = this.$refs[value][0].getValues()[0];
			this.detail.tree.all_data[index].showtype = false;
		},
		formatDate(date) {
			var year = date.getFullYear();
			var month = date.getMonth() + 1;
			var day = date.getDate();
			if(month<10){
				month = "0" + month;
			}
			if(day<10){
				day = "0" + day;
			}
			return `${year}-${month}-${day}`;
		},
		// 选择时间
		showtype(index) {
			this.selectname = this.detail.tree.all_data[index].name;
			this.selectIndex = index;
			this.detail.tree.all_data[index].showtype = true;
		},
		time_oneSave(value) {
			this.form[this.selectname] = this.formatDate(value);
			this.detail.tree.all_data[this.selectIndex].showtype = false;
		},
		// 选择时间范围
		time_twoSave(date) {
			const [start, end] = date;
			this.detail.tree.all_data[this.selectIndex].showtype = false;
			this.form[this.selectname] = `${this.formatDate(start)} - ${this.formatDate(end)}`;
		},
		regionSave(value){
			this.detail.tree.all_data[this.selectIndex].showtype = false;
			this.form[this.selectname] = `${value[0].name} ${value[1].name} ${value[2].name}`;
		},
		onUrl(item){
			return this.$router.push({
				path: '/pre/create',
				query: { cid: item.id }
			})
		}
  }
}
</script>

<style lang="less" scoped>
.form-cell{border-bottom: 1px solid #ebedf0;}
.van-checkbox-group,.van-radio-group{
	background-color: #fff;
}
.van-checkbox-group,.van-radio-group{overflow: hidden;padding: 0 16px 0 26px;}
.van-checkbox,.van-radio{margin-bottom: 5px;}
.one-uploader-container{padding:10px 16px;background: #fff;}
.image-list img {
  margin: 0 5px;
}
.van-image-preview__image {
  img.van-image__img {
    background: #ffffff;
  }
}
.one-upload {
  .van-image {
    float: left;
    margin: 5px 5px;
  }
}
</style>
