<template>
  <div class="one-uploader-container">
    <div class="van-uploader">
      <div class="van-uploader__wrapper">
        <div class="van-uploader__preview" v-for="(item,index) in data" :key="index">
          <div class="van-image van-uploader__preview-image">
            <van-image :src="item" class="van-image__img" style="object-fit: cover;">
              <template v-slot:error>加载失败</template>
            </van-image>
          </div>
          <i class="van-icon van-icon-clear van-uploader__preview-delete" @click="onDelete(index)"></i>
        </div>

        <div class="van-uploader__upload" @click="chooseImage">
          <i class="van-icon van-icon-photograph van-uploader__upload-icon">
            <!---->
          </i>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { fetchGet, fetchPost } from '@/utils/http.js'
export default {
  name: 'OneFieldIndex',
  props: ['data', 'total'],
  methods: {
    //  pc端的图片上传有问题；
    //  手机端上传没有问题； pc端走到uploadImage时返回 未经许可；
    //  需要获取储存权限吗？
    chooseImage: function() {
      var _this = this
      window.wx.chooseImage({
        sizeType: ['original', 'compressed'],
        sourceType: ['album', 'camera'],
        success: function(res) {
          console.log('选中的图片', res.localIds)
          _this.syncUpload(res.localIds)
        }
      })
    },
    onDelete(index) {
      this.$emit('deldata', index)
    },
    syncUpload: function(localIds) {
      var _this = this
      if (localIds.length > 0) {
        var localId = localIds.pop()
        console.log('上传图片之前',localId,JSON.stringify(localIds))
        window.wx.uploadImage({
          localId: localId.toString(),
          isShowProgressTips: 1,
          success: function(res) {
            console.log('开始上传的数据', res.serverId)

            // TODO 图片上传
            fetchPost(`media/create`, { media_id: res.serverId }).then(mres => {
              console.log('mres', mres)

              window.setTimeout(function() {
                _this.getShow(mres.data.id)
              }, 2000)
            })

            window.setTimeout(function() {
              _this.syncUpload(localIds)
            }, 2000)
          }
        })
      }
    },
    // 获取图片
    async getShow(id) {
      var _this = this
      await fetchGet(`media/view`, { id }).then(res => {
        console.log('media/view', res.data.image)
        _this.$emit('syncdata', res.data.image)
      })
    }
  }
}
</script>

<style scoped lang="less">
.one-upload {
  .van-image {
    float: left;
    margin: 5px 5px;
  }
}
</style>
