<template>
  <div id="take-form">
    <van-nav-bar :title="pageTitle" left-arrow left-text="返回" @click-left="onClickLeft" />

    <van-swipe>
      <van-swipe-item v-for="(image, index) in covers" :key="index">
        <img v-lazy="image" :data-index="index" @click="onShowImage(index)" style="width:100%;" />
      </van-swipe-item>
    </van-swipe>

    <div style="margin: 16px;margin-top:30px;">
      <van-button round block type="info" to="sign">邀请客户签名</van-button>
    </div>
  </div>
</template>

<script>
import { fetchGet } from '@/utils/http.js'

import { ImagePreview } from 'vant'

export default {
  name: 'SignCreate',
  components: {},
  data() {
    return {
      cid: 0,
      title: '',
      pageTitle: '',
      message: '',
      contract: {},
      covers: [],
      fields: [],
      isEdit: false,
      uploader: [{ url: 'https://img.yzcdn.cn/vant/leaf.jpg' }]
    }
  },

  mounted: function() {
    this.cid = this.$route.query.cid
    this.title = this.$route.query.title
    this.pageTitle = this.title + '签名'
    this.getCate()
  },
  methods: {
    submit() {},
    // 异步校验函数返回 Promise
    asyncValidator(val) {
      return new Promise(resolve => {
        this.$toast.loading('验证中...')

        setTimeout(() => {
          this.$toast.clear()
          resolve(/\d{6}/.test(val))
        }, 1000)
      })
    },
    onSubmit() {},

    onShowImage(index) {
      ImagePreview({
        images: this.covers,
        startPosition: index,
        onClose() {
          // do something
        }
      })
    },
    // 出错提示
    onFailed(errorInfo) {
      console.log('failed', errorInfo)
    },

    // 返回上页
    onClickLeft() {
      history.go(-1)
    },
    // 获取商户详情
    getCate() {
      fetchGet(`sign-cate/view`, { id: this.cid }).then(res => {
        console.log('view', res.data)
        this.title = res.data.title
        this.pageTitle = res.data.title + '签名'
        this.contract = res.data.contract
        this.covers = this.contract.covers
        this.fields = res.data.fields
      })
    }
  }
}
</script>

<style scoped></style>
