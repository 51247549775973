
// 线上
//思索
const hostUrl = 'https://oneh5.yikeyiqun.cn'
//一客一群old
//const hostUrl = 'https://gone.yikeyiqun.cn'
//一客一群
//const hostUrl = 'https://qone.yikeyiqun.cn'
//深圳
//const hostUrl = 'https://szone.yikeyiqun.cn'
//喻总
//const hostUrl = 'https://yuone.yikeyiqun.cn'
//兔宝宝
//const hostUrl = 'https://tbbone.huiquancai.cn'
//乐尚喻总
//const hostUrl = 'https://one.lszzjj.com'
//兔宝宝-提莫整家
//const hostUrl = 'https://tmzjone.huiquancai.cn'
//兔宝宝--测试
//const hostUrl = 'https://tbbone.yikeyiqun.cn'
//兔宝宝--怡居科材
//const hostUrl = 'https://yjkcone.yjkc.top'

// 线上
//思索
const showUrl = 'http://show.yikeyiqun.cn'
//一客一群old
//const showUrl = 'http://gshow.yikeyiqun.cn'
//一客一群
//const showUrl = 'http://qshow.yikeyiqun.cn'
//深圳
//const showUrl = 'http://szshow.yikeyiqun.cn'
//喻总
//const showUrl = 'http://yushow.yikeyiqun.cn'
//兔宝宝
//const showUrl = 'http://tbbshow.huiquancai.cn'
//乐尚喻总
//const showUrl = 'http://show.lszzjj.com'
//兔宝宝-提莫整家
//const showUrl = 'http://tmzjshow.huiquancai.cn'
//兔宝宝-测试
//const showUrl = 'http://tbbshow.yikeyiqun.cn'
//兔宝宝--怡居科材
//const showUrl = 'http://yjkcshow.yjkc.top'


// 后台请求接口
// 线上
//思索
const baseUrl = 'https://api.yikeyiqun.cn/one-in/v1/'
//一客一群old
//const baseUrl = 'https://gapi.yikeyiqun.cn/one-in/v1/'
//一客一群
//const baseUrl = 'https://qapi.yikeyiqun.cn/one-in/v1/'
//深圳
//const baseUrl = 'https://szapi.yikeyiqun.cn/one-in/v1/'
//喻总
//const baseUrl = 'https://yuapi.yikeyiqun.cn/one-in/v1/'
//兔宝宝
//const baseUrl = 'https://tbbapi.huiquancai.cn/one-in/v1/'
//乐尚喻总
//onst baseUrl = 'https://api.lszzjj.com/one-in/v1/'
//兔宝宝-提莫整家
//const baseUrl = 'https://tmzjapi.huiquancai.cn/one-in/v1/'
//兔宝宝-测试
//const baseUrl = 'https://tbbapi.yikeyiqun.cn/one-in/v1/'
//兔宝宝--怡居科材
//const baseUrl = 'https://yjkcapi.yjkc.top/one-in/v1/'

// 动态字段合同
// 线上
//思索
const contractUrl = 'http://h5.yikeyiqun.cn/one/contract/create'
//一客一群old
//const contractUrl = 'http://gh5.yikeyiqun.cn/one/contract/create'
//一客一群
//const contractUrl = 'http://qh5.yikeyiqun.cn/one/contract/create'
//深圳
//const contractUrl = 'http://szh5.yikeyiqun.cn/one/contract/create'
//喻总
//const contractUrl = 'http://yuh5.yikeyiqun.cn/one/contract/create'
//兔宝宝
//const contractUrl = 'http://tbbh5.huiquancai.cn/one/contract/create'
//乐尚喻总
//const contractUrl = 'http://h5.lszzjj.com/one/contract/create'
//兔宝宝-提莫整家
//const contractUrl = 'http://tmzjh5.huiquancai.cn/one/contract/create'
//兔宝宝-测试
//const contractUrl = 'http://tbbh5.yikeyiqun.cn/one/contract/create'
//兔宝宝--怡居科材
//const contractUrl = 'http://yjkch5.yjkc.top/one/contract/create'


// H5当前运行网址
// 线上
//思索
const jsUrl = 'http://oneh5.yikeyiqun.cn/'
//一客一群old
//const jsUrl = 'http://gone.yikeyiqun.cn/'
//一客一群
//const jsUrl = 'http://qone.yikeyiqun.cn/'
//深圳
//const jsUrl = 'http://szone.yikeyiqun.cn/'
//喻总
//const jsUrl = 'http://yuone.yikeyiqun.cn/'
//兔宝宝
//const jsUrl = 'http://tbbone.huiquancai.cn/'
//乐尚喻总
//const jsUrl = 'http://one.lszzjj.com/'
//兔宝宝-提莫整家
//const jsUrl = 'http://tmzjone.huiquancai.cn/'
//兔宝宝-测试
//const jsUrl = 'http://tbbone.yikeyiqun.cn/'
//兔宝宝--怡居科材
//const jsUrl = 'http://yjkcone.yjkc.top/'


// 预置功能列表
const funMenus = [
  {
    label: '群档案',
    icon: '#iconA20',
    url: 'guest',
    bgcolor: '#FEF6E9',
  },
  {
    label: '单据上传',
    icon: '#iconA26',
    url: 'document/select',
    bgcolor: '#F7F2FD',
  },
  {
    label: '合同',
    icon: '#iconA93',
    url: 'contract/select',
    bgcolor: '#ebf1fe',
  },
  {
    label: '预约',
    icon: '#iconA89',
    url: '/pre/select',
    bgcolor: '#FEF6E9',
  },
  {
    label: '拍照',
    icon: '#iconA66',
    url: 'take/select',
    bgcolor: '#F7F2FD',
  },
  {
    label: '跟进',
    icon: '#iconA20',
    url: 'complaint',
    bgcolor: '#F7F2FD',
  },
  {
    label: '我的任务',
    icon: '#iconA97',
    url: 'job/myjob',
    bgcolor: '#F7F2FD',
  },
]

export {hostUrl, baseUrl, jsUrl, contractUrl, funMenus, showUrl }
