import Vue from 'vue'
import App from './App.vue'
import Vant from 'vant'
import { Lazyload, ImagePreview } from 'vant'
import storage from 'store'
import 'vant/lib/index.css'
import router from './router'
import store from './store/'
import './assets/font/iconfont.css'
require('./assets/font/iconfont.js')
//import VueAMap from 'vue-amap'
import moment from 'moment'
//Vue.use(VueAMap)
import { jsUrl } from '@/utils/params'
import { getQueryString } from '@/utils/common'
import '@vant/touch-emulator'

import vueMiniPlayer from 'vue-mini-player'
Vue.use(vueMiniPlayer)
import { GET_MERCHANT_ID } from './store/mutation-types'

Vue.use(Vant)
Vue.use(Lazyload)
Vue.use(ImagePreview)

Vue.config.productionTip = false
//Vue.prototype.$wx = wx

//VueAMap.initAMapApiLoader({
  //key: '7e3d396744729f2c698920c5dae69d54',
 // plugin: [
   // 'AMap.Autocomplete',
   // 'AMap.PolyEditor',
   // 'AMap.CircleEditor',
   // 'AMap.Scale',
   // 'AMap.OverView',
   // 'AMap.ToolBar',
   // 'AMap.MapType',
   // 'AMap.PlaceSearch',
   // 'AMap.Geolocation',
 // ],
//})

Vue.filter('dateformat', function(dataStr, pattern = 'YYYY-MM-DD HH:mm') {
  if (dataStr) {
    return moment.unix(dataStr).format(pattern)
  } else {
    return dataStr
  }
})

//微信授权
router.beforeEach((to, from, next) => {
  let merchant_id = getQueryString('merchant_id')
	if(to.name == "Home"){
		store.dispatch('setMerchantId', merchant_id)
	}else{
		if (merchant_id) {
			store.dispatch('setMerchantId', merchant_id)
		} else {
			store.dispatch('setMerchantId', storage.get(GET_MERCHANT_ID))
		}
	}

  if (!/micromessenger/i.test(navigator.userAgent)) {
    next()
    return
  }

  if (to.name == 'auth') {
    next()
    return
  }

  let work_user = sessionStorage.getItem('work_user')
  if (!work_user) {
    sessionStorage.setItem('wxRedirectUrl', to.fullPath)

    store.dispatch('setMerchant').then((res) => {
      console.log('merchant', res)
      let appId = res.workwechat_corp_id
      let redirectUrl = encodeURIComponent(jsUrl + 'auth')
      console.log('merchant:', appId, redirectUrl)

      if (process.env.NODE_ENV == 'development') {
        next()
      } else {
        window.location.href = `https://open.weixin.qq.com/connect/oauth2/authorize?appid=${appId}&redirect_uri=${redirectUrl}&response_type=code&scope=snsapi_userinfo&state=STATE#wechat_redirect`
      }
    })
  } else {
		store.dispatch('setMerchant').then(() => {
			next()
		})
  }
})

new Vue({
  router,
  store,
  render: (h) => h(App),
}).$mount('#app')
