<template>
	<div class="home">
		<van-nav-bar title="单据上传" left-arrow left-text="返回" @click-left="onClickLeft" />
		<van-grid :column-num="3" :clickable="true">
			<van-grid-item
				v-for="(item, index) in list"
				:key="index"
				:icon="item.icon || 'orders-o'"
				:text="item.label"
				:to="item.url"
				:dot="item.dot"
				@click="onUrl(item)"
			>
				<div slot="text">{{ item.title }}</div>
			</van-grid-item>
		</van-grid>
		<div>{{ detail }}</div>
	</div>
</template>

<script>
import { fetchGet } from '@/utils/http.js'
import storage from 'store'
export default {
  name: 'Upload',
  components: {
  },
  data() {
    return {
      list:[],
			detail:""
    }
  },

  mounted: function () {
		var aaa=storage.get('GET_CHAT')
		fetchGet(`fromdata/index`, { merchant_id: aaa }).then((e) => {
			this.list = e.data;
		})
  },
  methods: {
		onClickLeft() {
			history.go(-1)
		},
		onUrl(item){
			return this.$router.push({
				path: '/document/form',
				query: { cid: item.id }
			})
		}
  }
}
</script>

<style lang="less" scoped>
.image-list {
  width: 100%;
  height: 100px;
  overflow: hidden;
  overflow-y: scroll;
  position: relative;
}
.image-list img {
  margin: 0 5px;
}
.van-image-preview__image {
  img.van-image__img {
    background: #ffffff;
  }
}
</style>
