<template>
  <div id="take-form">
    <van-nav-bar :title="pageTitle" left-arrow left-text="返回" @click-left="onClickLeft" />

    <van-form validate-first @failed="onFailed">
      <van-cell-group>
        <van-field v-model="message" rows="5" autosize type="textarea" maxlength="5000" placeholder="请输入留言"
          show-word-limit />

        <van-field name="uploader">
          <template #input>
            <one-uploader :data="covers" :total="9" @syncdata="chooseImage" @deldata="onDelete" />
          </template>
        </van-field>

        <van-field title="视频上传">
          <template #input>
            <one-upload-video :video="video" @syncVideo="syncVideo" />
          </template>
        </van-field>
      </van-cell-group>

      <div style="margin: 30px;">
        <van-button round block type="info" @click="submit" native-type="submit">提交</van-button>
      </div>
    </van-form>
  </div>
</template>

<script>
  import storage from 'store'
  import {
    fetchGet,
    fetchPost
  } from '@/utils/http.js'
  import OneUploader from '@/components/one-uploader/index.vue'
  import OneUploadVideo from '@/components/one-upload-video/index.vue'
  import wxJs from '@/utils/wx-sdk'
  // import AMap from 'AMap'

  export default {
    name: 'TakeCreate',
    components: {
      OneUploader,
      OneUploadVideo
    },
    data() {
      return {
        isChat: false,
        chatInfo: {},
        cid: 0,
        title: '',
        pageTitle: '',
        message: '',
        covers: [],
        location: {},
        video: '',
        userAgent: '',
      }
    },

    mounted: function () {
      this.cid = this.$route.query.cid
      if (this.cid) {
        this.$store.dispatch('getTakeCateId', this.cid)
        this.getWorkCheck()
        this.getCate()
      } else {
        return this.$router.push({
          path: '/'
        })
      }
      //  判断当前客户端类型；
      // 定位的时候用;
      console.log('用户设备', navigator.userAgent.toLowerCase())
      let flag = navigator.userAgent.match(
        /(phone|pad|pod|iPhone|iPod|ios|iPad|Android|Mobile|BlackBerry|IEMobile|MQQBrowser|JUC|Fennec|wOSBrowser|BrowserNG|WebOS|Symbian|Windows Phone)/i
      ) == null ? 'PC' : 'Mobile';
      console.log('flag', flag)
      this.userAgent = flag;
    },
    methods: {
      chooseImage(res) {
        this.covers.push(res)
      },
      onDelete(index) {
        this.covers.splice(index, 1)
      },
      syncVideo(res) {
        this.video = res
      },

      // 获取企业认证
      getWorkCheck() {
        wxJs.init(['checkJsApi', 'agentConfig', 'chooseImage']).then(wx => {
          window.wx.checkJsApi({
            jsApiList: [
              'agentConfig',
              'sendChatMessage',
              'selectExternalContact',
              'getCurExternalChat'
            ],
            success: res => {
              this.$notify({
                message: '已获取微信config认证',
                type: 'primary'
              })
              console.log('wx.checkJsApi', res)
              this.agentInit()
              wx.getLocation({
                type: 'wgs84',
                success: function (lres) {
                  this.location = lres
                }
              })
            },
            error: err => {
              console.log('err', err)
            }
          })
        })
      },

      // 获取应用参数
      async agentInit() {
        let that = this
        wxJs
          .agentInit(['getCurExternalChat', 'sendChatMessage', 'chooseImage', 'uploadImage'])
          .then(cc => {
            console.log('agentList', cc)
            window.wx.invoke('getCurExternalChat', {}, function (res) {
              if (res.err_msg == 'getCurExternalChat:ok') {
                let chatId = res.chatId //返回当前客户群的群聊ID
                localStorage.setItem('chatId', chatId)
                that.$notify({
                  message: '已获取群ID成功',
                  type: 'primary'
                })
                that.$toast.clear()
              } else {
                that.$notify({
                  message: '群ID获取失败',
                  type: 'danger'
                })
                //错误处理
                console.log('wx.invoke', res.err_msg)
              }
            })
          })
      },

      submit() {
        var that = this;
        var chat = storage.get('GET_CHAT');
        //  首先通过wx jssdk 获取当前地里位置； 通过高德地图api进行坐标转换
        // if (that.userAgent == 'Mobile') {
        //   wxJs.init(['getLocation']).then(function () {
        //     //  微信获取定位
        //     window.wx.getLocation({
        //       type: 'wgs84', // 默认为wgs84的gps坐标，如果要返回直接给openLocation用的火星坐标，可传入'gcj02'
        //       success: function (res) {
        //         let latitude = Number(res.latitude); // 纬度，浮点数，范围为90 ~ -90
        //         let longitude = Number(res.longitude); // 经度，浮点数，范围为180 ~ -180。
        //         // 将企业微信gps坐标转为高德坐标
        //         AMap.convertFrom([longitude, latitude], 'gps', function (status, result) {
        //           if (result.info == 'ok') {
        //             let lng = Number(result.locations[0].lng),
        //                 lat = Number(result.locations[0].lat);
        //             var gpss = [lng, lat];
        //             // 高德配置 获取实际地址； 
        //             AMap.plugin('AMap.Geocoder', function () {
        //               let geocoder = new AMap.Geocoder({
        //                 city: "全国", //城市设为北京，默认：“全国”
        //                 radius: 1000 //范围，默认：500
        //               });
        //               geocoder.getAddress(gpss, function (status, res) {
        //                 if (status == 'complete' && res.regeocode) {
        //                   let address = res.regeocode.formattedAddress;
        //                   // ajax请求
        //                   fetchPost(`take/create`, {
        //                     begin_time: that.timeValue,
        //                     cate_id: that.cid,
        //                     guest_id: chat.id,
        //                     covers: that.covers,
        //                     content: that.message,
        //                     video: that.video,
        //                     address: address,
        //                   }).then(res => {
        //                     that.$dialog
        //                       .alert({
        //                         title: '完成',
        //                         message: res.message
        //                       })
        //                       .then(() => {
        //                         return that.$router.push({
        //                           path: '/'
        //                         })
        //                       })
        //                   })
        //                 } else {
        //                   that.$dialog
        //                     .alert({
        //                       title: '失败',
        //                       message: JSON.stringify(res)
        //                     })
        //                 }
        //               })
        //             })
        //           }
        //         })
        //       },
        //       fail: function (res) {
        //         console.log('失败', res)
        //         that.$dialog
        //           .alert({
        //             title: '失败',
        //             message: '请重试'
        //           })
        //       }
        //     })
        //   })
        //   //   获取定位 end
        // } 
        // else {
          // ajax请求
          fetchPost(`take/create`, {
            begin_time: that.timeValue,
            cate_id: that.cid,
            guest_id: chat.id,
            covers: that.covers,
            content: that.message,
            video: that.video,
          }).then(res => {
            console.log('res', res)
            wxJs
              .agentInit(["openExistedChatWithMsg"])
              .then(() => {
                  if(res.data.send_message=="0"){
                    that.$dialog
                      .alert({
                        title: '提示',
                        message: res.message
                      })
                      .then(() => {
                        return that.$router.push({ path: '/' })
                      })
                      
                  }else{
                      // 返回当前客户群的群聊ID
                      window.wx.invoke("openExistedChatWithMsg", {
                          chatId: res.data.chat_id,
                          msg: {
                              msgtype: "link",
                              link: {
                                  title: res.data.message.title,
                                  desc: res.data.message.desc,
                                  url: res.data.message.link,
                                  imgUrl: res.data.message.imgUrl
                              }
                          }
                      },function(res){
                          if (res.err_msg == "openExistedChatWithMsg:ok"){
                              return that.$router.push({
                                path: '/'
                              })
                          }else{
                              return that.$router.push({
                                path: '/'
                              })
                          }
                      });
                  }
              })
            // that.$dialog
            //   .alert({
            //     title: '完成',
            //     message: res.message
            //   })
            //   .then(() => {
            //     return that.$router.push({
            //       path: '/'
            //     })
            //   })
          })
        // }
      },
      // 异步校验函数返回 Promise
      asyncValidator(val) {
        return new Promise(resolve => {
          this.$toast.loading('验证中...')

          setTimeout(() => {
            this.$toast.clear()
            resolve(/\d{6}/.test(val))
          }, 1000)
        })
      },

      beforeRead(file) {
        if (file.type !== 'image/jpeg') {
          this.$toast('请上传 jpg 格式图片')
          return false
        }

        return false
      },

      // 出错提示
      onFailed(errorInfo) {
        console.log('failed', errorInfo)
      },

      // 返回上页
      onClickLeft() {
        history.go(-1)
      },
      // 获取商户详情
      getCate() {
        fetchGet(`take-cate/view`, {
          id: this.cid
        }).then(res => {
          console.log('view', res.data)
          this.title = res.data.title
          this.pageTitle = res.data.title + '拍照'
          if (res.data.template != 'null') {
            this.message = res.data.template
          }
        })
      },

    }
  }
</script>

<style scoped></style>