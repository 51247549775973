<template>
  <div class="home">
    <van-nav-bar title="预约" left-arrow left-text="返回" @click-left="onClickLeft" />

    <van-divider color="#ff3300">选择分类</van-divider>

    <van-empty v-show="menus.length == 0" description="请补充分类" />

    <van-grid :column-num="3" :clickable="true">
      <van-grid-item
        v-for="(item, index) in menus"
        :key="index"
        :icon="item.icon || 'photo-o'"
        :text="item.label"
        :to="item.url"
        :dot="item.dot"
        @click="onUrl(item)"
      >
        <div slot="text">{{ item.title }}</div>
      </van-grid-item>
    </van-grid>
  </div>
</template>

<script>
export default {
  name: 'Home',
  components: {},
  provide() {
    return {
      reload: this.reload
    }
  },
  data() {
    return {
      pid: 0,
      isReady: false,
      isRouterAlive: true,
      menus: []
    }
  },
  watch: {
    $route(to, from) {
      if (to.query.id != from.query.id) {
        this.pid = to.query.id
        this.$store.dispatch('getPreCatePid', this.pid)
        this.initData()
      }
    }
  },
  mounted() {
    this.pid = this.$route.query.id
    console.log('pid', this.pid)
    if (this.pid) {
      this.$store.dispatch('getPreCatePid', this.pid)
    }
    this.initData()
  },
  created: function() {},
  methods: {
    reload() {
      this.isRouterAlive = false
      this.$nextTick(() => (this.isRouterAlive = true))
    },

    // 获取本页需要的数据
    initData() {
      this.$store.dispatch('getPreCate', this.pid).then(res => {
        this.menus = res
        this.isReady = true
      })
    },

    onUrl(item) {
      console.log(item)
      if (item.children.length) {
        return this.$router.push({
          path: '/pre/select',
          query: { id: item.id }
        })
      } else {
        return this.$router.push({
          path: '/pre/create',
          query: { cid: item.id }
        })
      }
    },

    onClickLeft() {
      history.go(-1)
    }
  }
}
</script>

<style>
.one-icon {
  width: 1em;
  height: 1em;
  font-size: 1.5em;
  vertical-align: -0.15em;
  fill: currentColor;
  overflow: hidden;
}
.one-content {
  width: 100%;
  padding: 30px 0;
  border-radius: 10px;
}
.wrapper {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
}

.block {
  width: 120px;
  height: 120px;
  align-items: center;
  justify-content: center;
  display: flex;
  background-color: rgba(255, 255, 255, 0.8);
  border-radius: 5px;
}
</style>
