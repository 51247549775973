<template>
  <div class="sign-index">
    <van-nav-bar title="合同列表" left-arrow left-text="返回" @click-left="onClickLeft" />

    <van-empty description="暂无数据" />
  </div>
</template>

<script>
import { fetchGet } from '@/utils/http.js'
export default {
  name: 'signIndex',
  data() {
    return {
      page: 1,
      data: []
    }
  },

  mounted() {
    this.initData()
  },
  created: function() {
    if (this.$route.params.page) {
      this.page = this.$route.params.page
      console.log(this.page)
    }
  },
  methods: {
    // 返回上一页
    onClickLeft() {
      this.$router.go(-1)
    },
    // 获取本页需要的数据
    initData() {
      this.getSignList()
      this.isReady = true
    },

    // 获取签名列表
    getSignList() {
      fetchGet(`contract/index`, { page: this.page }).then(res => {
        let data = res.data
        this.data = data
        console.log('res', this.data)
      })
    }
  }
}
</script>

<style>
</style>