import { fetchPost, fetchGet } from '@/utils/http.js'
import {
  COMPLAINT_ID,
  COMPLAINT,
  COMPLAINT_CREATE,
  COMPLAINT_INFO,
  COMPLAINT_TYPE_LIST,
} from '../mutation-types'

const state = {
  // 预约
  complaint_id: 0,
  complaint: [],
  complaint_info: {},
  complaint_type_list: [],
}

const mutations = {
  [COMPLAINT_ID]: (state, id) => {
    state.complaint_id = id
  },
  [COMPLAINT]: (state, res) => {
    state.complaint = res
  },
  [COMPLAINT_CREATE]: (state, res) => {
    state.complaint_info = res
  },
  [COMPLAINT_INFO]: (state, res) => {
    state.complaint_info = res
  },
  [COMPLAINT_TYPE_LIST]: (state, res) => {
    state.complaint_type_list = res
  },
}

const actions = {
  getComplaintId({ commit }, id) {
    commit(COMPLAINT_ID, id)
  },
  getComplaintTypeList({ commit }) {
    return new Promise((resolve, reject) => {
      fetchGet(`complaint/type`, {})
        .then((res) => {
          commit(COMPLAINT_TYPE_LIST, res)
          resolve(res)
        })
        .catch((e) => {
          reject(e)
        })
    })
  },
  getComplaint({ commit }, params) {
    return new Promise((resolve, reject) => {
      fetchGet(`complaint/index`, params)
        .then((res) => {
          commit(COMPLAINT, res)
          resolve(res)
        })
        .catch((e) => {
          reject(e)
        })
    })
  },
  getComplaintView({ commit }, params) {
    return new Promise((resolve, reject) => {
      fetchGet(`complaint/view`, params)
        .then((res) => {
          commit(COMPLAINT_INFO, res)
          resolve(res)
        })
        .catch((e) => {
          reject(e)
        })
    })
  },
  createComplaint({ commit }, data) {
    return new Promise((resolve, reject) => {
      fetchPost(`contract/create`, data)
        .then((res) => {
          commit(COMPLAINT_INFO, res.data)
          resolve(res)
        })
        .catch((e) => {
          reject(e)
        })
    })
  },
}

export default {
  namespace: true,
  state,
  mutations,
  actions,
}
