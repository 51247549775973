<template>
  <div class="one-datetime-container">
    <van-field
      readonly
      clickable
      :label="item.title"
      name="begin_time"
      :value="item.value == 'null' ? '' : item.value"
      :placeholder="item.value || '点击选择时间'"
      @click="showPopup"
      is-link
    />

    <van-popup v-model="showPicker" position="bottom">
      <van-datetime-picker
        :type="type"
        v-model="value"
        title="选择时间"
        :filter="filter"
        :min-date="minDate"
        :formatter="formatter"
        @cancel="showPicker = false"
        @confirm="confirmPicker"
      />
    </van-popup>
  </div>
</template>

<script>
import moment from 'moment'
import { fetchPost } from '@/utils/http.js'
export default {
  name: 'OneDatetime',
  props: ['item', 'guest_id'],
  data() {
    var value = new Date()
    return {
      minDate: value,
      value: '',
      showPicker: false,
    }
  },
  mounted: function() {
    switch (this.item.type) {
      case 'date':
        this.type = 'date'
        break
      default:
        this.type = 'datetime'
    }
  },
  methods: {
    // 显示弹窗
    showPopup() {
      this.showPicker = true
    },

    // 确认选择的时间
    confirmPicker(val) {
      this.isValue = true
      console.log('time', val)
      switch (this.item.type) {
        case 'date':
          this.value = moment(val).format('YYYY-MM-DD')
          break
        default:
          this.value = moment(val).format('YYYY-MM-DD HH:mm')
      }
      this.item.value = this.value
      this.showPicker = false
      let form = {
        field: this.item.name,
        value: this.value,
        guest_id: this.guest_id,
        config_id: this.item.id,
      }
      console.log('form', form)
      fetchPost(`guest/save`, form).then((res) => {
        console.log('res', res)
        this.$notify({ type: 'success', message: res.message })
      })
    },
    filter(type, options) {
      if (type === 'minute') {
        return options.filter((option) => option % 10 === 0)
      }
      return options
    },
    // 选项格式化函数
    formatter(type, value) {
      if (type === 'year') {
        return `${value}年`
      } else if (type === 'month') {
        return `${value}月`
      } else if (type === 'day') {
        return `${value}日`
      } else if (type === 'hour') {
        return `${value}时`
      } else if (type === 'minute') {
        return `${value}分`
      } else if (type === 'second') {
        return `${value}秒`
      }
      return value
    },
  },
}
</script>
