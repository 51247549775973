<template>
  <div style="line-height:80px;text-align:center;">授权登录中...</div>
</template>

<script>
export default {
  name: 'Auth',
  mounted() {
    console.log('test', this.$route)
    if (this.$route.query.code) {
      let params = {
        code: this.$route.query.code,
        platform: 'workWechat',
      }

      this.$store.dispatch('Login', params).then((res) => {
        sessionStorage.setItem('work_user', JSON.stringify(res.data))
        let redirectUrl = sessionStorage.getItem('wxRedirectUrl')
        this.$router.replace(redirectUrl)
      })
    } else {
      console.log('auth res', this.$route.query)
      this.$router.replace('/')
    }
  },
}
</script>
