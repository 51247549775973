import { fetchPost, fetchGet } from '@/utils/http.js'
import axios from 'axios'
import storage from 'store'
import {
  TAKE_CATE_PID,
  TAKE_CATE_ID,
  TAKE_CATE,
  TAKE_CATE_INFO,
  TAKE_ID,
  TAKE_INFO,
  TAKE_CREATE,
  TAKE_VIDEO,
} from '../mutation-types'

const state = {
  // 预约
  take_cate_id: 0,
  take_cate_pid: 0,
  take_cate: {},
  take_cate_info: {},
  take_id: 0,
  take_info: {},
  take_video: '',
}

const mutations = {
  [TAKE_CATE_PID]: (state, id) => {
    state.take_cate_pid = id
  },
  [TAKE_CATE_ID]: (state, id) => {
    state.take_cate_id = id
  },
  [TAKE_CATE]: (state, res) => {
    state.take_cate = res
  },
  [TAKE_CATE_INFO]: (state, res) => {
    state.take_cate_info = res
  },
  [TAKE_ID]: (state, id) => {
    state.take_id = id
  },
  [TAKE_INFO]: (state, res) => {
    state.take_info = res
  },
  [TAKE_CREATE]: (state, res) => {
    state.take_info = res
  },
  [TAKE_VIDEO]: (state, res) => {
    state.take_video = res
  },
}

const actions = {
  getTakeCatePid({ commit }, id) {
    return commit(TAKE_CATE_PID, id)
  },
  getTakeCateId({ commit }, id) {
    return commit(TAKE_CATE_ID, id)
  },
  getVideo({ commit }, params, config) {
    return new Promise((resolve, reject) => {
      axios
        .post(`file/videos`, params, config)
        .then((res) => {
          commit(TAKE_VIDEO, res.data)
          resolve(res.data)
        })
        .catch((e) => {
          reject(e)
        })
    })
  },
  getTakeCate({ commit }, pid) {
		var form={
			chartid:storage.get('GET_CHAT_ID'),
			pid:pid
		}
    return new Promise((resolve, reject) => {
      fetchGet(`take-cate/index`, form)
        .then((res) => {
          commit(TAKE_CATE, res.data)
          resolve(res.data)
        })
        .catch((e) => {
          reject(e)
        })
    })
  },
  getTakeCateInfo({ commit }) {
    return new Promise((resolve, reject) => {
      fetchGet(`take-cate/view`, { id: state.take_cate_id })
        .then((res) => {
          commit(TAKE_CATE_INFO, res.data)
          resolve()
        })
        .catch((e) => {
          reject(e)
        })
    })
  },
  createTake({ commit }, data) {
    return new Promise((resolve, reject) => {
      fetchPost(`take/create`, data)
        .then((res) => {
          commit(TAKE_INFO, res.data)
          resolve(res)
        })
        .catch((e) => {
          reject(e)
        })
    })
  },
}

export default {
  namespace: true,
  state,
  mutations,
  actions,
}
